// quotes.js
const quotes = [
  { text: 'Education is the most powerful weapon which you can use to change the world.', author: 'Nelson Mandela' },
  { text: 'An investment in knowledge pays the best interest.', author: 'Benjamin Franklin' },
  { text: 'Live as if you were to die tomorrow. Learn as if you were to live forever.', author: 'Mahatma Gandhi' },
  { text: 'The roots of education are bitter, but the fruit is sweet.', author: 'Aristotle' },
  { text: 'Education is not preparation for life; education is life itself.', author: 'John Dewey' },
  { text: 'The more that you read, the more things you will know. The more that you learn, the more places you’ll go.', author: 'Dr. Seuss' },
  { text: 'Develop a passion for learning. If you do, you will never cease to grow.', author: 'Anthony J. D’Angelo' },
  { text: 'Change is the end result of all true learning.', author: 'Leo Buscaglia' },
  { text: 'The purpose of education is to replace an empty mind with an open one.', author: 'Malcolm S. Forbes' },
  { text: 'It is the mark of an educated mind to be able to entertain a thought without accepting it.', author: 'Aristotle' },
  { text: 'Education is the movement from darkness to light.', author: 'Allan Bloom' },
  { text: 'Tell me and I forget. Teach me and I remember. Involve me and I learn.', author: 'Benjamin Franklin' },
  { text: 'The beautiful thing about learning is that no one can take it away from you.', author: 'B.B. King' },
  { text: 'You are always a student, never a master. You have to keep moving forward.', author: 'Conrad Hall' },
  { text: 'Wisdom is not a product of schooling but of the lifelong attempt to acquire it.', author: 'Albert Einstein' },
  { text: 'Education is the passport to the future, for tomorrow belongs to those who prepare for it today.', author: 'Malcolm X' },
  { text: 'Learning never exhausts the mind.', author: 'Leonardo da Vinci' },
  { text: 'The only limit to our realization of tomorrow will be our doubts of today.', author: 'Franklin D. Roosevelt' },
  { text: 'The only thing that interferes with my learning is my education.', author: 'Albert Einstein' },
  { text: 'Success is not final, failure is not fatal: it is the courage to continue that counts.', author: 'Winston Churchill' },
  { text: 'The expert in anything was once a beginner.', author: 'Helen Hayes' },
  { text: 'Learning is not attained by chance, it must be sought for with ardor and diligence.', author: 'Abigail Adams' },
  { text: 'The future belongs to those who believe in the beauty of their dreams.', author: 'Eleanor Roosevelt' },
  { text: 'A person who never made a mistake never tried anything new.', author: 'Albert Einstein' },
  { text: 'Do not wait to strike till the iron is hot; but make it hot by striking.', author: 'William Butler Yeats' },
  { text: 'The function of education is to teach one to think intensively and to think critically. Intelligence plus character – that is the goal of true education.', author: 'Martin Luther King Jr.' },
  { text: 'It does not matter how slowly you go as long as you do not stop.', author: 'Confucius' },
  { text: 'Success is the sum of small efforts, repeated day in and day out.', author: 'Robert Collier' },
  { text: 'Learning is a treasure that will follow its owner everywhere.', author: 'Chinese Proverb' },
  { text: 'The best way to predict your future is to create it.', author: 'Abraham Lincoln' },
  { text: "You don't have to be great to start, but you have to start to be great.", author: 'Zig Ziglar' },
  { text: 'The secret of getting ahead is getting started.', author: 'Mark Twain' },
  { text: 'I find that the harder I work, the more luck I seem to have.', author: 'Thomas Jefferson' },
  { text: "Don't let what you cannot do interfere with what you can do.", author: 'John Wooden' },
  { text: 'The only way to do great work is to love what you do.', author: 'Steve Jobs' },
  { text: 'I never learned from a man who agreed with me.', author: 'Robert A. Heinlein' },
  { text: 'Strive not to be a success, but rather to be of value.', author: 'Albert Einstein' },
  { text: "Don't watch the clock; do what it does. Keep going.", author: 'Sam Levenson' },
  { text: 'You are never too old to set another goal or to dream a new dream.', author: 'C.S. Lewis' },
  { text: 'We learn more by looking for the answer to a question and not finding it than we do from learning the answer itself.', author: 'Lloyd Alexander' },
  { text: 'Do what you can, with what you have, where you are.', author: 'Theodore Roosevelt' },
  { text: 'Success usually comes to those who are too busy to be looking for it.', author: 'Henry David Thoreau' },
  { text: 'The only real mistake is the one from which we learn nothing.', author: 'Henry Ford' },
  { text: 'Perseverance is not a long race; it is many short races one after the other.', author: 'Walter Elliot' },
  { text: 'There are no secrets to success. It is the result of preparation, hard work, and learning from failure.', author: 'Colin Powell' },
  { text: "You don't have to see the whole staircase, just take the first step.", author: 'Martin Luther King Jr.' },
  { text: 'Dream big and dare to fail.', author: 'Norman Vaughan' },
  { text: 'Everything you’ve ever wanted is on the other side of fear.', author: 'George Addair' },
  { text: 'What lies behind us and what lies before us are tiny matters compared to what lies within us.', author: 'Ralph Waldo Emerson' },
  { text: 'Act as if what you do makes a difference. It does.', author: 'William James' },
  { text: 'Keep your face always toward the sunshine—and shadows will fall behind you.', author: 'Walt Whitman' },
  { text: 'The only person you are destined to become is the person you decide to be.', author: 'Ralph Waldo Emerson' },
  { text: 'What you do today can improve all your tomorrows.', author: 'Ralph Marston' },
  { text: 'A champion is defined not by their wins but by how they can recover when they fall.', author: 'Serena Williams' },
  { text: 'I never dreamed about success. I worked for it.', author: 'Estée Lauder' },
  { text: 'Don’t be afraid to give up the good to go for the great.', author: 'John D. Rockefeller' },
  { text: 'The best revenge is massive success.', author: 'Frank Sinatra' },
  { text: 'I attribute my success to this: I never gave or took any excuse.', author: 'Florence Nightingale' },
  { text: 'A river cuts through rock, not because of its power, but because of its persistence.', author: 'Jim Watkins' },
  { text: 'The road to success and the road to failure are almost exactly the same.', author: 'Colin R. Davis' },
  { text: "Success seems to be connected with action. Successful people keep moving. They make mistakes, but they don't quit.", author: 'Conrad Hilton' },
  { text: "Don’t be pushed around by the fears in your mind. Be led by the dreams in your heart.", author: 'Roy T. Bennett' },
  { text: 'The harder the conflict, the greater the triumph.', author: 'George Washington' },
  { text: 'The only limit to our realization of tomorrow is our doubts of today.', author: 'Franklin D. Roosevelt' },
  { text: 'Hardships often prepare ordinary people for an extraordinary destiny.', author: 'C.S. Lewis' },
  { text: 'Keep your eyes on the stars, and your feet on the ground.', author: 'Theodore Roosevelt' },
  { text: 'If you can dream it, you can do it.', author: 'Walt Disney' },
  { text: 'Dreams don’t work unless you do.', author: 'John C. Maxwell' },
  { text: 'It does not matter how slowly you go as long as you do not stop.', author: 'Confucius' },
  { text: 'The journey of a thousand miles begins with a single step.', author: 'Lao Tzu' },
  { text: 'The best way to predict your future is to create it.', author: 'Peter Drucker' },
  { text: 'The secret of change is to focus all of your energy not on fighting the old, but on building the new.', author: 'Socrates' },
  { text: 'In the middle of every difficulty lies opportunity.', author: 'Albert Einstein' },
  { text: 'The future depends on what you do today.', author: 'Mahatma Gandhi' },
  { text: 'There is no substitute for hard work.', author: 'Thomas Edison' },
  { text: 'Don’t wish it were easier; wish you were better.', author: 'Jim Rohn' },
  { text: 'Challenges are what make life interesting; overcoming them is what makes life meaningful.', author: 'Joshua Marine' },
  { text: 'Believe in yourself and all that you are.', author: 'Christian D. Larson' },
  { text: 'Your time is limited, so don\'t waste it living someone else\'s life.', author: 'Steve Jobs' },
  { text: 'The only way to achieve the impossible is to believe it is possible.', author: 'Socrates' }
];

export default quotes;