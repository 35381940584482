import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../helpers/AuthProvider';
import gsap from 'gsap';
import MultipleChoiceResult from './ResultTypes/MultipleChoiceResult';
import TrueFalseResult from './ResultTypes/TrueFalseResult';
import FillInTheBlankResult from './ResultTypes/FillInTheBlankResult';
import ShortAnswerResult from './ResultTypes/ShortAnswerResult';
import './ResultsPage.css';

function ResultsPage() {
  const { id: classroomId } = useParams();
  const { token } = useAuth();
  const [quizDetails, setQuizDetails] = useState(null);
  const [error, setError] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [backendUpdated, setBackendUpdated] = useState(false);
  const containerRef = useRef(null);
  const progressCircleRef = useRef(null);
  const progressTextRef = useRef(null); // New ref for the progress text

  // Refs for left and right arrow icons
  const leftIconRef = useRef(null);
  const rightIconRef = useRef(null);

  // Dynamic color mappings based on difficulty
  const difficultyStrokeColorMap = {
    novice: "#b19cd9",
    apprentice: "#99ccff",
    adept: "#91d8cc",
    expert: "#ff9999",
    master: "#ffe066",
  };

  const difficultyBackgroundMap = {
    novice: "#d6c6ff",
    apprentice: "#cce5ff",
    adept: "#b7f0e0",
    expert: "#ffbdbd",
    master: "#d6c6ff",
  };

  // Fetch quiz details from the backend
  useEffect(() => {
    axios
      .get(`/api/quizzes/?classroom_id=${classroomId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data && response.data.questions) {
          setQuizDetails(response.data);
        } else {
          setError("No quiz details found.");
        }
      })
      .catch((error) => {
        console.error(
          "Error fetching quiz details:",
          error.response?.data || error.message
        );
        setError("Error fetching quiz details.");
      });
  }, [classroomId, token]);

  // Keyboard navigation for questions and animate arrow icons
  useEffect(() => {
    if (!quizDetails || !quizDetails.questions) return;
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") {
        if (leftIconRef.current) {
          leftIconRef.current.classList.add("active");
          setTimeout(() => leftIconRef.current.classList.remove("active"), 200);
        }
        setSelectedQuestionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (e.key === "ArrowRight") {
        if (rightIconRef.current) {
          rightIconRef.current.classList.add("active");
          setTimeout(() => rightIconRef.current.classList.remove("active"), 200);
        }
        setSelectedQuestionIndex((prevIndex) =>
          prevIndex < quizDetails.questions.length - 1 ? prevIndex + 1 : prevIndex
        );
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [quizDetails]);

  // GSAP animation for page load and progress ring/text
  useLayoutEffect(() => {
    if (quizDetails && containerRef.current && progressCircleRef.current && progressTextRef.current) {
      const totalQuestions = quizDetails.questions.length;
      const correctCount = quizDetails.questions.filter((q) => q.correct).length;
      const percentageLocal = totalQuestions > 0 ? Math.round((correctCount / totalQuestions) * 100) : 0;
      const radius = 90;
      const circumference = 2 * Math.PI * radius;
      const targetDashoffset = circumference * (1 - percentageLocal / 100);

      const tl = gsap.timeline();
      tl.fromTo(
        containerRef.current,
        { scale: 0.5, opacity: 0 },
        { scale: 1, opacity: 1, duration: 0.5, ease: "power2.out" }
      );
      tl.to(
        progressCircleRef.current,
        { strokeDashoffset: targetDashoffset, duration: 1, ease: "power2.out" },
        "-=0.2"
      );
      // Animate the progress text from 0 to the target percentage
      const progressCounter = { value: 0 };
      tl.to(progressCounter, {
        duration: 1,
        value: percentageLocal,
        ease: "power2.out",
        onUpdate: () => {
          progressTextRef.current.textContent = Math.round(progressCounter.value) + "%";
        }
      }, "-=1");
    }
  }, [quizDetails]);

  // Derived values: compute score and pass status
  let correctCount = 0;
  let totalQuestions = 0;
  let hasPassed = false;
  if (quizDetails && quizDetails.questions) {
    totalQuestions = quizDetails.questions.length;
    correctCount = quizDetails.questions.filter((q) => q.correct).length;
    hasPassed = correctCount >= 9;
  }
  const percentage = totalQuestions > 0 ? Math.round((correctCount / totalQuestions) * 100) : 0;

  // Update the backend with pass status and score (0-10) once results are computed
  useEffect(() => {
    if (quizDetails && !backendUpdated) {
      const patchData = {
        has_passed: hasPassed,
        score: correctCount,
      };

      axios
        .patch(`/api/quizzes/?classroom_id=${classroomId}`, patchData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Updated quiz results:", response.data);
          setBackendUpdated(true);
        })
        .catch((error) => {
          console.error(
            "Error updating quiz results:",
            error.response?.data || error.message
          );
        });
    }
  }, [quizDetails, backendUpdated, classroomId, token, hasPassed, correctCount]);

  if (error) return <p>{error}</p>;
  if (
    !quizDetails ||
    !quizDetails.questions ||
    !quizDetails.start_time ||
    !quizDetails.end_time
  ) {
    return <p>Loading results...</p>;
  }

  // Time calculation in mm:ss format
  const startTime = new Date(quizDetails.start_time);
  const endTime = new Date(quizDetails.end_time);
  const diffMs = endTime - startTime;
  const diffSec = Math.floor(diffMs / 1000);
  const minutes = Math.floor(diffSec / 60);
  const seconds = diffSec % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  // Determine dynamic colors based on selected difficulty
  const selectedDifficulty = quizDetails.selected_difficulty
    ? quizDetails.selected_difficulty.toLowerCase()
    : "novice";
  const progressStrokeColor =
    difficultyStrokeColorMap[selectedDifficulty] || "#b19cd9";
  const infoBackgroundColor =
    difficultyBackgroundMap[selectedDifficulty] || "#d6c6ff";

  // Render the appropriate question preview component
  const renderQuestionPreview = () => {
    const selectedQuestion = quizDetails.questions[selectedQuestionIndex];
    switch (selectedQuestion.type) {
      case "Multiple Choice":
        return (
          <MultipleChoiceResult
            key={selectedQuestionIndex}
            questionData={selectedQuestion}
            difficulty={selectedDifficulty}
          />
        );
      case "True or False":
        return (
          <TrueFalseResult
            key={selectedQuestionIndex}
            questionData={selectedQuestion}
            difficulty={selectedDifficulty}
          />
        );
      case "Fill in the Blank":
        return (
          <FillInTheBlankResult
            key={selectedQuestionIndex}
            questionData={selectedQuestion}
            difficulty={selectedDifficulty}
          />
        );
      case "Short Answer":
        return (
          <ShortAnswerResult
            key={selectedQuestionIndex}
            questionData={selectedQuestion}
            difficulty={selectedDifficulty}
          />
        );
      default:
        return <p>Unknown question type.</p>;
    }
  };

  const handleSelectQuestion = (idx) => {
    if (quizDetails.questions[idx]) {
      setSelectedQuestionIndex(idx);
    }
  };

  // For the circular progress ring, use a radius of 90
  const radius = 90;
  const circumference = 2 * Math.PI * radius;

  return (
    <div className="results-page-container" ref={containerRef}>
      {/* Top Section */}
      <div className="results-page__header">
        <h1 className="results-page__title">{quizDetails.title}</h1>
        <div className="results-page__info">
          <div
            className="results-page__score-info"
            style={{ background: infoBackgroundColor }}
          >
            Score: {correctCount} / {totalQuestions}
          </div>
          <div
            className="results-page__time-info"
            style={{ background: infoBackgroundColor }}
          >
            Time: {formattedTime}
          </div>
          <div
            className="results-page__pass-info"
            style={{ background: infoBackgroundColor }}
          >
            {hasPassed ? "Passed" : "Failed"}
          </div>
        </div>
      </div>

      {/* Middle Section */}
      <div className="results-page__middle">
        <div className="results-page__progress">
          <div className="circular-progress">
            <svg className="progress-ring" width="200" height="200">
              <circle
                className="progress-ring__background"
                stroke="#eee"
                strokeWidth="10"
                fill="transparent"
                r="90"
                cx="100"
                cy="100"
              />
              <circle
                ref={progressCircleRef}
                className="progress-ring__progress"
                stroke={progressStrokeColor}
                strokeWidth="10"
                fill="transparent"
                r="90"
                cx="100"
                cy="100"
                style={{
                  strokeDasharray: circumference,
                  strokeDashoffset: circumference,
                }}
              />
              <text
                ref={progressTextRef} // Using the ref here
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                className="progress-ring__text"
                transform="rotate(90 100 100)"
              >
                0%
              </text>
            </svg>
          </div>
          <div className="results-page__question-preview">
            {renderQuestionPreview()}
          </div>
        </div>
      </div>

      {/* Bottom Section - Navigation Circles & Arrow Icons */}
      <div className="results-page__bottom-section">
        <div className="results-page__navigation">
          {Array.from({ length: 10 }).map((_, idx) => {
            const q = quizDetails.questions[idx];
            const isCorrect = q ? q.correct : false;
            return (
              <div
                key={idx}
                className={`nav-circle ${
                  isCorrect ? "nav-circle--correct" : "nav-circle--incorrect"
                } ${selectedQuestionIndex === idx ? "nav-circle--active" : ""}`}
                onClick={() => handleSelectQuestion(idx)}
              >
                {idx + 1}
              </div>
            );
          })}
        </div>
        <div className="results-page__navigation-help">
          <div className="results-page__navigation-help-text">Navigation</div>
          <div className="results-page__navigation-icons">
            <i ref={leftIconRef} className="bi bi-arrow-left-square-fill results-page__navigation-icon"></i>
            <i ref={rightIconRef} className="bi bi-arrow-right-square-fill results-page__navigation-icon"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultsPage;
