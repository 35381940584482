import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../helpers/AuthProvider';
import { gsap } from 'gsap';
import './CreateQuiz.css';
import ClassroomService from './QuizTitleService';
import { useSidebar } from '../Sidebar/SidebarContext';
import QuizService from './QuizService';
import LoadingScreen from './LoadingScreen';
import quotes from './Quotes';

/* 
  Reusable StarName component that checks if the text overflows and adds the "overflowed" class.
  The CSS for .star-name and .star-name.overflowed::after should be defined in your CreateQuiz.css.
*/
function StarName({ name }) {
  const starNameRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const el = starNameRef.current;
      if (el) {
        // If the scroll width is greater than the client width, the text is overflowing.
        setIsOverflowing(el.scrollWidth > el.clientWidth);
      }
    };

    // Check on mount and when the name changes.
    checkOverflow();

    // Optionally, re-check on window resize.
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [name]);

  return (
    <span
      ref={starNameRef}
      className={`star-name ${isOverflowing ? 'overflowed' : ''}`}
      style={{ position: 'relative' }} // Required for positioning the pseudo-element.
    >
      {name}
    </span>
  );
}

function CreateQuiz() {
  const { id: classroomId } = useParams();
  const { token } = useAuth();
  const { refreshSidebar } = useSidebar();
  const { setSidebarContent } = useSidebar();
  const [topic, setTopic] = useState('');
  const [selectedDifficulty, setSelectedDifficulty] = useState(null);
  const [selectedQuizTypes, setSelectedQuizTypes] = useState(["Default (mixed)"]);
  const [classroomTitle, setClassroomTitle] = useState('');
  const [quote, setQuote] = useState({ text: '', author: '' });
  const [placeholder, setPlaceholder] = useState('');
  const [isQuizGenerating, setIsQuizGenerating] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const containerRef = useRef(null);
  const maxCharacters = 200;
  const classroomService = useRef(null);
  const hasGeneratedTitle = useRef(false);
  const typingTimeout = useRef(null);
  const navigate = useNavigate();
  const quizService = useRef(null);
  const topicInputRef = useRef(null);

  // Mapping for start button colors based on difficulty.
  const startButtonColorMap = {
    Novice: "#b19cd9",
    Apprentice: "#99ccff",
    Adept: "#91d8cc",
    Expert: "#ff9999",
    Master: "#ffd666",
  };

  const topicIdeas = [
    'The history of the Roman Empire',
    'The periodic table and its elements',
    'Basics of quantum physics',
    'The American Civil War',
  ];

  // States for storing stars.
  const [communityStars, setCommunityStars] = useState([]);
  const [recentStars, setRecentStars] = useState([]);

  // -------------------------------
  // Reset state to defaults every time the classroomId changes.
  useEffect(() => {
    console.log("Resetting quiz options to default values for classroomId:", classroomId);
    setTopic('');
    setSelectedDifficulty(null);
    setSelectedQuizTypes(["Default (mixed)"]);
    setClassroomTitle('');
  }, [classroomId]);
  // -------------------------------

  useEffect(() => {
    // Setup tooltips for info icons.
    const icons = document.querySelectorAll('.section-heading-info-icon');
    const listeners = [];
    icons.forEach((icon) => {
      const wrapper = icon.parentElement;
      if (!wrapper) return;
      const handleMouseOver = () => {
        let tooltip = wrapper.querySelector('.create-quiz__custom-tooltip');
        if (!tooltip) {
          tooltip = document.createElement('div');
          tooltip.className = 'create-quiz__custom-tooltip';
          wrapper.appendChild(tooltip);
        }
        tooltip.textContent = icon.getAttribute('data-tooltip');
        tooltip.style.display = 'block';
      };
      const handleMouseOut = () => {
        const tooltip = wrapper.querySelector('.create-quiz__custom-tooltip');
        if (tooltip) {
          tooltip.style.display = 'none';
        }
      };
      icon.addEventListener('mouseover', handleMouseOver);
      icon.addEventListener('mouseout', handleMouseOut);
      listeners.push({ icon, handleMouseOver, handleMouseOut });
    });
    return () => {
      listeners.forEach(({ icon, handleMouseOver, handleMouseOut }) => {
        icon.removeEventListener('mouseover', handleMouseOver);
        icon.removeEventListener('mouseout', handleMouseOut);
      });
    };
  }, []);

  useEffect(() => {
    // Set up random quotes, a placeholder topic, GSAP animation, and initialize ClassroomService.
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);
    const randomTopic = topicIdeas[Math.floor(Math.random() * topicIdeas.length)];
    setPlaceholder(`Enter the topic of the quiz you would like to generate, e.g. ${randomTopic}`);

    gsap.fromTo(
      containerRef.current,
      { zIndex: -1, scale: 0.5, opacity: 0 },
      { zIndex: 1, scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
    );

    // Initialize ClassroomService for title generation.
    classroomService.current = new ClassroomService({
      onMessage: async (data) => {
        if (data.type === 'room_title' && data.title) {
          const sanitizedTitle = data.title.replace(/^"|"$/g, '');
          setClassroomTitle(sanitizedTitle);
        }
      },
    });

    return () => classroomService.current?.close();
  }, [classroomId, token]);

  useEffect(() => {
    const fetchStudioStars = async () => {
      if (!token) {
        console.error('User is not authenticated for fetching studio stars.');
        return;
      }
      try {
        const response = await axios.get('https://dev.chromecopilot.ai/api/get_studio_stars/', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setCommunityStars(response.data.community_stars);
        setRecentStars(response.data.recent_stars);
      } catch (error) {
        console.error('Failed to fetch studio stars:', error.response?.data || error.message);
      }
    };

    fetchStudioStars();
  }, [token]);

  useEffect(() => {
    if (topicInputRef.current) {
      topicInputRef.current.focus();
    }
  }, []);

  // Load quiz draft from local storage (if any) for the given classroomId.
  useEffect(() => {
    const draft = localStorage.getItem(`quizDraft_${classroomId}`);
    if (draft) {
      try {
        const { topic, selectedDifficulty, selectedQuizTypes, classroomTitle } = JSON.parse(draft);
        console.log("Loading quiz draft from local storage:", { topic, selectedDifficulty, selectedQuizTypes, classroomTitle });
        if (topic) setTopic(topic);
        if (selectedDifficulty) setSelectedDifficulty(selectedDifficulty);
        if (selectedQuizTypes) setSelectedQuizTypes(selectedQuizTypes);
        if (classroomTitle) setClassroomTitle(classroomTitle);
      } catch (error) {
        console.error("Error parsing draft data from local storage", error);
      }
    }
  }, [classroomId]);

  // Save quiz draft to local storage whenever the relevant state changes.
  useEffect(() => {
    const draft = { topic, selectedDifficulty, selectedQuizTypes, classroomTitle };
    localStorage.setItem(`quizDraft_${classroomId}`, JSON.stringify(draft));
  }, [topic, selectedDifficulty, selectedQuizTypes, classroomTitle, classroomId]);

  // Delayed title generation when the topic input changes.
  const handleInputChange = (e) => {
    const newTopic = e.target.value;
    setTopic(newTopic);
    hasGeneratedTitle.current = false;
    if (typingTimeout.current) clearTimeout(typingTimeout.current);
    typingTimeout.current = setTimeout(() => {
      if (newTopic.trim()) {
        classroomService.current?.sendRoomTitleRequest(newTopic, token);
      }
    }, 2000);
  };

  // Trigger immediate title generation when a suggested topic is clicked.
  const handleSuggestedTopicClick = (topicName) => {
    setTopic(topicName);
    hasGeneratedTitle.current = false;
    classroomService.current?.sendRoomTitleRequest(topicName, token);
  };

  const handleDifficultyClick = (difficulty) => {
    setSelectedDifficulty(difficulty);
  };

  const handleQuizTypeClick = (type) => {
    if (type === "Default (mixed)") {
      setSelectedQuizTypes(["Default (mixed)"]);
      return;
    }
    let currentSelection = [...selectedQuizTypes];
    if (currentSelection.length === 1 && currentSelection[0] === "Default (mixed)") {
      currentSelection = [];
    }
    if (currentSelection.includes(type)) {
      currentSelection = currentSelection.filter((t) => t !== type);
      if (currentSelection.length === 0) {
        currentSelection = ["Default (mixed)"];
      }
      setSelectedQuizTypes(currentSelection);
      return;
    }
    if (currentSelection.length >= 3) {
      setSelectedQuizTypes(["Default (mixed)"]);
      return;
    }
    const newSelection = [...currentSelection, type];
    setSelectedQuizTypes(newSelection);
  };

  // On "Start Quiz", create the quiz in the backend.
  const handleStartQuiz = async () => {
    try {
      if (!classroomId || !token) {
        console.error("Missing classroomId or token! Cannot start quiz.");
        return;
      }

      setIsQuizGenerating(true);

      await axios.post(
        `/api/quizzes/`,
        {
          classroom_id: classroomId,
          topic,
          selected_difficulty: selectedDifficulty,
          selected_quiz_type: selectedQuizTypes,
          title: classroomTitle,
          has_started: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Clear the draft since the quiz is starting.
      localStorage.removeItem(`quizDraft_${classroomId}`);
      quizService.current = new QuizService({
        classroomId,
        token,
        onMessage: (data) => {
          if (Array.isArray(data) && data.length > 0) {
            setLoadingComplete(true);
            setTimeout(() => {
              navigate(`/studio/classroom/quiz/${classroomId}`);
            }, 1000);
          }
        },
      });
      await quizService.current.connect();

      let questionTypeText = "";
      if (selectedQuizTypes.length === 1 && selectedQuizTypes[0] === "Default (mixed)") {
        questionTypeText =
          "a diverse range of question formats, including Multiple Choice, True/False, Fill in the Blank, and Short Answer";
      } else {
        questionTypeText = `exclusively ${selectedQuizTypes.join(', ')} questions`;
      }
      let promptContent = "";
      switch (selectedDifficulty) {
        case 'Novice':
          promptContent = `Generate a beginner-friendly quiz on the topic "${topic}" with 10 independent questions. Each question should be self-contained and simple, with no repeated clues or overlapping details that might reveal the answer to another question. The quiz must only include ${questionTypeText} and focus solely on fundamental concepts using clear, basic language.`;
          break;
        case 'Apprentice':
          promptContent = `Generate an Apprentice-level quiz on the topic "${topic}" with 10 independent questions. Each question must stand on its own, avoiding any hints or reused details that could give away answers in another question. The quiz should only include ${questionTypeText} and cover core concepts with moderate complexity and clear, concise wording.`;
          break;
        case 'Adept':
          promptContent = `Generate an Adept-level quiz on the topic "${topic}" with 10 independent questions. Ensure that each question is distinct and self-contained—none should hint at or repeat information from another. The quiz must only include ${questionTypeText} and challenge the user to apply a deeper understanding of the topic, using language that fosters analytical thinking while keeping every question completely separate.`;
          break;
        case 'Expert':
          promptContent = `Generate an Expert-level quiz on the topic "${topic}" with 10 independent questions. Each question should be highly accurate, thought-provoking, and entirely self-contained, ensuring that no detail or clue from one question can help answer another. The quiz should only include ${questionTypeText} and use precise language to test advanced knowledge and critical analysis.`;
          break;
        case 'Master':
          promptContent = `Generate a Master-level quiz on the topic "${topic}" with 10 independent questions. The quiz must push the boundaries of conventional questioning—each question should be highly accurate, challenging, and completely self-contained, with no overlap in clues or key details that might reveal another answer. Only include ${questionTypeText} and use sophisticated language to stimulate comprehensive mastery and creative problem-solving.`;
          break;
        default:
          promptContent = `Generate a quiz on the topic "${topic}" with 10 independent questions that only include ${questionTypeText}. Ensure that each question is unique and self-contained so that the answer to one does not provide any hints or clues for any other.`;
      }
      const messages = [{ role: 'user', content: promptContent }];
      if (quizService.current && typeof quizService.current.sendQuizRequest === 'function') {
        quizService.current.sendQuizRequest(messages);
      }
    } catch (error) {
      console.error("Error starting quiz:", error.response?.data || error.message);
    }
  };

  const handleAuthorClick = (author) => {
    const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(author)}`;
    window.open(searchUrl, '_blank');
  };

  // Helper function to generate relative time strings for stars.
  const getRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffSeconds = Math.floor((now - date) / 1000);
    if (diffSeconds < 60) {
      return "just now";
    } else if (diffSeconds < 3600) {
      const minutes = Math.floor(diffSeconds / 60);
      return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
    } else if (diffSeconds < 86400) {
      const hours = Math.floor(diffSeconds / 3600);
      return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
    } else if (diffSeconds < 172800) {
      return "yesterday";
    } else if (diffSeconds < 2592000) {
      const days = Math.floor(diffSeconds / 86400);
      return `${days} days ago`;
    } else if (diffSeconds < 31536000) {
      const months = Math.floor(diffSeconds / (30 * 86400));
      return months === 1 ? "1 month ago" : `${months} months ago`;
    } else {
      const years = Math.floor(diffSeconds / (365 * 86400));
      return years === 1 ? "1 year ago" : `${years} years ago`;
    }
  };

  const isStartQuizEnabled = topic.trim() && selectedDifficulty && selectedQuizTypes.length > 0;

  if (isQuizGenerating) {
    return (
      <LoadingScreen 
        quizTitle={classroomTitle || "Your Quiz"} 
        complete={loadingComplete} 
        message="Generating your quiz..."
        difficulty={selectedDifficulty}
      />
    );
  }

  return (
    <div ref={containerRef} className="create-quiz-container">
      <div id="tooltip-box"></div>
      <div className="vertical-row quiz-create-row">
        <div className="section-container">
          <h2 className="section-heading">Quiz Topic</h2>
          <div className="quiz-topic-tile">
            <textarea
              ref={topicInputRef}
              placeholder={placeholder}
              value={topic}
              onChange={handleInputChange}
              className="quiz-topic-input"
            />
            <div className="character-counter">{topic.length}/{maxCharacters}</div>
          </div>
        </div>

        <div className="section-container">
          <h2 className="section-heading">
            Difficulty{' '}
            <span className="info-icon-wrapper">
              <i
                className="bi bi-info-circle-fill section-heading-info-icon"
                data-tooltip="Select your quiz difficulty – check your mindmap for required levels!"
              ></i>
            </span>
          </h2>
          <div className="difficulty-container">
            {['Novice', 'Apprentice', 'Adept', 'Expert', 'Master'].map((option) => (
              <div
                key={option}
                className={`difficulty-box ${option.toLowerCase()} ${selectedDifficulty === option ? 'selected-option' : ''}`}
                onClick={() => handleDifficultyClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </div>

        <div className="section-container">
          <h2 className="section-heading">
            Quiz Type{' '}
            <span className="info-icon-wrapper">
              <i 
                className="bi bi-info-circle-fill section-heading-info-icon"
                data-tooltip="Pick up to 3 question types. Note: Fill in the Blank & Short Answer are toughest – choose wisely!"
              ></i>
            </span>
          </h2>
          <div className="quiz-types-tile">
            <div className="quiz-types-row">
              <div
                className={`quiz-type-box default-quiz-type-box ${selectedQuizTypes.includes('Default (mixed)') ? 'selected-option' : ''}`}
                onClick={() => handleQuizTypeClick('Default (mixed)')}
              >
                Default (mixed)
              </div>
              <div
                className={`quiz-type-box ${selectedQuizTypes.includes('Multiple Choice') ? 'selected-option' : ''}`}
                onClick={() => handleQuizTypeClick('Multiple Choice')}
              >
                Multiple Choice
              </div>
            </div>
            <div className="quiz-types-row">
              {['True or false', 'Fill in the blank', 'Short answer'].map((type) => (
                <div
                  key={type}
                  className={`quiz-type-box ${selectedQuizTypes.includes(type) ? 'selected-option' : ''}`}
                  onClick={() => handleQuizTypeClick(type)}
                >
                  {type}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="vertical-row suggested-topics-row">
        <div className="section-container suggested-topics-row">
          <h2 className="section-heading">
            Your Latest Topics{' '}
            <span className="info-icon-wrapper">
              <i 
                className="bi bi-info-circle-fill section-heading-info-icon"
                data-tooltip="Your recent topics are here – ready to test your knowledge?"
              ></i>
            </span>
          </h2>
          <div className="suggested-stars-tile">
            {recentStars.map((star, index) => (
              <React.Fragment key={index}>
                <div
                  className="suggested-star"
                  onClick={() => {
                    handleSuggestedTopicClick(star.name);
                  }}
                  onMouseEnter={(e) => {
                    const inner = e.currentTarget.querySelector('.star-date');
                    if (inner) inner.style.opacity = 1;
                  }}
                  onMouseLeave={(e) => {
                    const inner = e.currentTarget.querySelector('.star-date');
                    if (inner) inner.style.opacity = 0;
                  }}
                >
                  <div className="star-details">
                    <span className="star-dot">•</span>{' '}
                    <div className="star-details-inner">
                      <StarName name={star.name} />
                      {' '}
                      <span className="star-date" style={{ opacity: 0, transition: 'opacity 0.3s ease' }}>
                        {getRelativeTime(star.created_at)}
                      </span>
                    </div>
                  </div>
                </div>
                {index !== recentStars.length - 1 && <div className="star-divider"></div>}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="section-container">
          <h2 className="section-heading">
            Top Community Topics{' '}
            <span className="info-icon-wrapper">
              <i
                className="bi bi-info-circle-fill section-heading-info-icon"
                data-tooltip="See what topics are popular among the community and test your knowledge!"
              ></i>
            </span>
          </h2>
          <div className="top-stars-tile">
            {communityStars.map((star, index) => (
              <React.Fragment key={index}>
                <div
                  className="suggested-star"
                  onClick={() => {
                    handleSuggestedTopicClick(star.name);
                  }}
                  onMouseEnter={(e) => {
                    const inner = e.currentTarget.querySelector('.star-count');
                    if (inner) inner.style.opacity = 1;
                  }}
                  onMouseLeave={(e) => {
                    const inner = e.currentTarget.querySelector('.star-count');
                    if (inner) inner.style.opacity = 0;
                  }}
                >
                  <div className="star-details">
                    <span className="star-number">{index + 1}.</span>{' '}
                    <div className="star-details-inner">
                      <StarName name={star.name} />
                      {' '}
                      <span className="star-count" style={{ opacity: 0, transition: 'opacity 0.3s ease' }}>
                        {star.user_count} {star.user_count === 1 ? 'user' : 'users'}
                      </span>
                    </div>
                  </div>
                </div>
                {index !== communityStars.length - 1 && <div className="star-divider"></div>}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="vertical-row">
        <div className="quote-container">
          <div className="quote-text">{quote.text}</div>
          <div
            className="quote-author"
            onClick={() => handleAuthorClick(quote.author)}
          >
            — {quote.author}
          </div>
        </div>
        <button
          className={`start-quiz-button ${isStartQuizEnabled ? 'enabled' : 'disabled'}`}
          disabled={!isStartQuizEnabled}
          onClick={handleStartQuiz}
          style={isStartQuizEnabled ? { background: startButtonColorMap[selectedDifficulty] } : {}}
        >
          Start Quiz
        </button>
      </div>
    </div>
  );
}

export default CreateQuiz;
