// Import React and the CSS file
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../helpers/AuthProvider';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import useWindowSize from '../helpers/useWindowSize';
import './LandingPage5.css';

import paradigmLogo from '../../assets/images/logo-inspiration-compass-image-2-removebg-preview.png';

import HeroLP from './HeroLP/HeroLP';
import CreateTutor2LP from './CreateTutorLP/CreateTutor2LP.js';
import CopilotLP from './CopilotLP/CopilotLP';
import StudioLP from './StudioLP/StudioLP';
import MindMapLP from './MindMapLP/MindMapLP';
import TestimonialsLP from './TestimonialsLP/TestimonialsLP';
import PricingLP from './PricingLP/PricingLP';

gsap.registerPlugin(ScrollTrigger);

function LandingPage5() {
    const landingPageRef = useRef(null);

    const [showBanner, setShowBanner] = useState(false);
    const [bannerLocked, setBannerLocked] = useState(false); // This will "lock" the banner state during the mindmap section

    const [screenWidth, setScreenWidth] = useState(window.innerWidth); // Track window width manually
    

    const netflixBackgroundRef = useRef(null);

    const mindmapTitleContainerRef = useRef(null);
    const mindmapCanvasRef = useRef(null);

    const aboutTitleRef = useRef(null);

    const testimonialTitleRef = useRef(null);
    const testimonial1Ref = useRef(null);
    const testimonial2Ref = useRef(null);
    const testimonial3Ref = useRef(null);
    const testimonial4Ref = useRef(null);
    const testimonial5Ref = useRef(null);
    const testimonial6Ref = useRef(null);
    const testimonial7Ref = useRef(null);
    const testimonial8Ref = useRef(null);
    const testimonialButtonRef = useRef(null);

    useEffect(() => {
        const handleFontsLoaded = () => {
          document.body.classList.add('fonts-loaded');
        };
      
        if (document.fonts && document.fonts.ready) {
          document.fonts.ready.then(handleFontsLoaded);
        } else {
          window.addEventListener('load', handleFontsLoaded);
          return () => {
            window.removeEventListener('load', handleFontsLoaded);
          };
        }
    }, []);


    // After sign in
    const { user } = useAuth(); // Use the user object from AuthContext
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        if (user) { // If the user object is not null, it means the user is authenticated
            console.log("User is authenticated, navigating to studio...");
            navigate('/studio');
        }
    }, [user, navigate]); // Depend on user and navigate to ensure updates trigger the effect



    // Listen for actual window resizes (fix for Chrome DevTools testing)
    useEffect(() => {
        function updateWidth() {
            setScreenWidth(window.innerWidth); // Always store the real width
        }
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    // Scroll banner (Hide when in Mind Map section)
    const handleScroll = () => {
        const mindmapTitleContainer = mindmapTitleContainerRef.current;

        if (mindmapTitleContainer) {
        const zIndex = window.getComputedStyle(mindmapTitleContainer).zIndex;
        
        if (parseInt(zIndex) === 1) {
            setShowBanner(false);
            setBannerLocked(true);
        } else {
            setBannerLocked(false);
            setShowBanner(window.scrollY > 0); // Only show when scrolled down
        }
        }
    };

    useEffect(() => {
        handleScroll(); // Run on mount in case page loads mid-scroll
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [screenWidth]); // Run whenever screen width changes



    // Nav links
    const scrollToSection = (selector, offset = 0) => {
        const element = document.querySelector(selector);
        if (element) {
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - offset;
            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            });
        }
    };


    // Load in animations
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const delay = entry.target.getAttribute('data-animation-delay') || 0;
    
                        // GSAP timeline to animate with staggered/overlapping animations
                        const tl = gsap.timeline({
                            defaults: { duration: 1, ease: 'power2.out' }
                        });
    
                        // GSAP animation for each target with delay from data attribute
                        tl.fromTo(
                            entry.target,
                            { opacity: 0, y: 20 }, // Starting state
                            { opacity: 1, y: 0 },   // Final state
                            `+=${delay}` // Use the delay from the data attribute
                        );
    
                        // Stop observing the target after the animation
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,  // Trigger when 50% of the element is visible
            }
        );

        // Ref for elements
        const refs = [
            aboutTitleRef, 
            testimonialTitleRef, 
            testimonial1Ref, 
            testimonial2Ref, 
            testimonial3Ref, 
            testimonial4Ref, 
            testimonial5Ref, 
            testimonial6Ref, 
            testimonial7Ref, 
            testimonial8Ref, 
            testimonialButtonRef
        ];
        
        refs.forEach(ref => {
            if (ref.current) observer.observe(ref.current);
        });
    
        return () => {
            refs.forEach(ref => {
                if (ref.current) observer.unobserve(ref.current);
            });
        };
    }, []);

    return (
        <div className="landing-page preload" ref={landingPageRef}>

            <nav className="navbar">
                <div className="LP-navbar-left">
                    <div className="LP-logo-container">
                        <img
                            src={paradigmLogo}
                            alt="Paradigm Logo"
                            className="LP-paradigm-logo"
                            draggable='false'
                        />
                        <p className="navbar-logo-text">Paradigm</p>
                        <p className="navbar-logo-x">X</p>
                    </div>
                </div>
                <div className="navbar-right">
                    <button className="landing-page-button-2 button-secondary" onClick={() => window.location.href = 'https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2'}>
                        Log in
                    </button>
                    <button className="landing-page-button-1 button-primary" onClick={() => window.location.href = 'https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2'}>
                        Sign up
                    </button>
                </div>
            </nav>

            <div className="main">
                {/* Hero Section */}
                <HeroLP />

                <div className="features-section">
                    {/* Create Your AI 2 Section */}
                    <CreateTutor2LP />

                    {/* Copilot Section */}
                    <CopilotLP
                        landingPageRef={landingPageRef}
                    />

                    {/* Studio Section */}
                    <StudioLP 
                        landingPageRef={landingPageRef} 
                        mindmapTitleContainerRef={mindmapTitleContainerRef}
                        mindmapCanvasRef={mindmapCanvasRef}
                    />

                    {/* Introducing Mind Mao Screen */}
                    <div className='mind-map-title-container' ref={mindmapTitleContainerRef}>
                        <p className='mind-map-title-1'>Introducing</p>
                        <p className='mind-map-title-2'>Mind Map</p>
                    </div>

                    {/* Mind Map Section */}
                    <MindMapLP 
                        landingPageRef={landingPageRef} 
                        mindmapTitleContainerRef={mindmapTitleContainerRef}
                        mindmapCanvasRef={mindmapCanvasRef}
                    />
                </div>

                {/* About Section */}
                {/*
                <AboutLP 
                    aboutTitleRef={aboutTitleRef} 
                />
                */}

                {/* Testimonials Section */}
                <TestimonialsLP 
                    width={screenWidth}
                    testimonialTitleRef={testimonialTitleRef}
                    testimonial1Ref={testimonial1Ref}
                    testimonial2Ref={testimonial2Ref}
                    testimonial3Ref={testimonial3Ref}
                    testimonial4Ref={testimonial4Ref}
                    testimonial5Ref={testimonial5Ref}
                    testimonial6Ref={testimonial6Ref}
                    testimonial7Ref={testimonial7Ref}
                    testimonial8Ref={testimonial8Ref}
                    testimonialButtonRef={testimonialButtonRef}
                />

                {/* Pricing Section */}
                <PricingLP />

                <div className="footer-section">
                    <div className="footer-seperator"></div>
                    <div className="footer-content-container">
                        <p>© 2025 All Rights Reserved, Paradigm®</p>
                        <div className="terms-container">
                            <p>Privacy policy</p>
                            <p>|</p>
                            <p>Terms of use</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`LP-bottom-banner ${showBanner ? 'show' : 'hide'}`}>
                {/* Conditionally render the text wrapper only if screen width is 500px or more */}
                {screenWidth > 500 && (
                    <div className='LP-bottom-banner-text-wrapper'>
                        {screenWidth < 1000 ? (
                            <>
                                <p className='hero-button-container-text-bold'>
                                    Try our features for free. Cancel anytime.
                                </p>
                                <p className='hero-button-container-text'>
                                    We'll remind you 7 days before your trial ends.
                                </p>
                            </>
                        ) : (
                            <>
                                <p className='hero-button-container-text-bold'>
                                    Try our features for free.
                                </p>
                                <p className='hero-button-container-text'>
                                    Cancel anytime. We'll remind you 7 days before your trial ends.
                                </p>
                            </>
                        )}
                    </div>
                )}
                <button
                    className="LP-bottom-banner-button landing-page-button-1 button-primary" 
                    onClick={() => alert('Get started!')}
                >
                    Accept free trial
                </button>
            </div>

        </div>
    );
}

export default LandingPage5;