import React, { useState, useEffect, useRef } from 'react';
import './OpenResponseQuestion.css';
import AnswerChecking from './AnswerChecking';
import gsap from 'gsap';
import { checkAnswer } from './CheckAnswerService';
import axios from 'axios';
import { useAuth } from '../../helpers/AuthProvider';
import { useParams } from 'react-router-dom';

function FillInTheBlankQuestion({ questionData, difficulty, onAnswerSubmit = () => {}, isLastQuestion }) {
  const [answer, setAnswer] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const { token } = useAuth();
  const { id: classroomId } = useParams();

  useEffect(() => {
    if (containerRef.current) {
      gsap.fromTo(
        containerRef.current,
        { scale: 0.5, opacity: 0 },
        { scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
      );
    }
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = () => {
    if (answer.trim()) {
      setIsChecking(true);
      checkAnswer(questionData.question, answer.trim())
        .then(async (result) => {
          // Prepare payload with updated explanation if answer is correct
          const payload = {
            questions: {
              ...questionData,
              userAnswer: answer.trim(),
              correct: result.answer_correct,
              explanation: result.answer_correct ? result.explanation : questionData.explanation,
            },
          };
          if (isLastQuestion) {
            payload.end_time = new Date().toISOString();
          }
          try {
            const response = await axios.patch(
              `/api/quizzes/?classroom_id=${classroomId}`,
              payload,
              { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log("✅ Answer saved successfully:", response.data);
          } catch (error) {
            console.error("❌ Error saving answer:", error.response?.data || error.message);
          }
          setIsChecking(false);
          // Use the updated explanation if correct, otherwise original explanation
          onAnswerSubmit(result.answer_correct, result.answer_correct ? result.explanation : questionData.explanation);
        })
        .catch((error) => {
          console.error("❌ Error checking answer:", error);
          setIsChecking(false);
          onAnswerSubmit(false, "There was an error checking your answer.");
        });
    }
  };

  return (
    <div ref={containerRef} className={`open-response-container ${difficulty.toLowerCase()}`}>
      <h3 className="question-text">{questionData.question}</h3>
      <input
        ref={inputRef}
        type="text"
        className="open-response-input"
        placeholder="Type your answer here..."
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
      <button 
        className="submit-answer-button"
        onClick={handleSubmit}
        disabled={!answer.trim()}
      >
        Submit Answer
      </button>
      {isChecking && <AnswerChecking />}
    </div>
  );
}

export default FillInTheBlankQuestion;