import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './CopilotLP.css';
import ArtistShowcaseLP from '../ArtistShowcaseLP/ArtistShowcaseLP';

// Copilot Images
import copilotSidebar from '../../../assets/landingPageImages/copilot-sidebar.png'
import copilotVoice from '../../../assets/landingPageImages/copilot-voice.png'
import copilotUploadFiles from '../../../assets/landingPageImages/copilot-upload-files.png'
import copilotYoutube from '../../../assets/landingPageImages/copilot-youtube.png'
import copilotQuickActions from '../../../assets/landingPageImages/copilot-quick-actions.png'
import copilotArtist from '../../../assets/landingPageImages/copilot-artist.png'

gsap.registerPlugin(ScrollTrigger);

function CopilotLP({ landingPageRef }) {
    // Store width
    const [width, setWidth] = useState(window.innerWidth);
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    
    const copilotContainerRef = useRef(null);
    const copilotTitle1Ref = useRef(null);
    const copilotTitle2Ref = useRef(null);

    const copilotSidebarTextRef = useRef(null);
    const copilotVoiceTextRef = useRef(null);
    const copilotResearchAssistantTextRef = useRef(null);
    const copilotYoutubeTextRef = useRef(null);
    const copilotQuickActionsTextRef = useRef(null);
    const netflixBackgroundRef = useRef(null);
    const copilotArtistTextRef = useRef(null);

    const copilotSidebarImageRef = useRef(null);
    const copilotVoiceImageRef = useRef(null);
    const copilotUploadFilesImageRef = useRef(null);
    const copilotYoutubeImageRef = useRef(null);
    const copilotQuickActionsImageRef = useRef(null);
    const copilotArtistImageRef = useRef(null);
    
    // New Copilot Section GSAP animation
    const initCopilotAnimation = () => {
        const copilotContainer = copilotContainerRef.current;

        const sidebarText = copilotSidebarTextRef.current;
        const voiceText = copilotVoiceTextRef.current;
        const researchAssistantText = copilotResearchAssistantTextRef.current;
        const youtubeText = copilotYoutubeTextRef.current;
        const quickActionsText = copilotQuickActionsTextRef.current;
        const artistText = copilotArtistTextRef.current;

        const sidebarImage = copilotSidebarImageRef.current;
        const voiceImage = copilotVoiceImageRef.current;
        const uploadFilesImage = copilotUploadFilesImageRef.current;
        const youtubeImage = copilotYoutubeImageRef.current;
        const quickActionsImage = copilotQuickActionsImageRef.current;
        const artistImage = copilotArtistImageRef.current;

        const netflixBackground = netflixBackgroundRef.current;

        // Decide the animation's 'y' transition value based on width and orientation
        const textAnimDistance = width < 1099 || isPortrait ? 0 : 100;

        if (
            copilotContainer 
            && sidebarText 
            && voiceText
            && researchAssistantText
            && youtubeText 
            && quickActionsText 
            && artistText 
            && netflixBackground
            && sidebarImage
            && voiceImage
            && uploadFilesImage
            && youtubeImage
            && quickActionsImage
            && artistImage
        ) {
            // Force each text element to center vertically
            gsap.set([
                sidebarText, 
                voiceText, 
                researchAssistantText, 
                youtubeText, 
                quickActionsImage, 
                artistText
            ], {
                translate3D: '50%, 50%, 0',
                immediateRender: true
            });

            // Force each image to center anchor
            gsap.set([
                sidebarImage, 
                voiceImage, 
                uploadFilesImage, 
                youtubeImage, 
                quickActionsImage, 
                artistImage
            ], {
                translate3D: '50%, 50%, 0',
                immediateRender: true
            });

            // Pin the studio-container until the animation is done
            ScrollTrigger.create({
                trigger: copilotContainer,
                id: 'copilotContainer2',
                start: 'top top+=5%', // Pin when 5% from top
                end: () => `+=${window.innerHeight * 8}`, // Pin until the second text has finished
                pin: true,
                scrub: true,
                pinSpacing: false,
                // markers: true, // Uncomment for debugging
            });

            // Create a GSAP timeline to synchronize animations
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: copilotContainer, // Sync animations to the pinning of the studio container
                    start: 'top top+=5%', // Start when the studio container is pinned
                    end: '+=400%', // Duration for both text animations
                    scrub: true,
                    // markers: true, // Uncomment for debugging
                },
            });

            // 1) SIDEBAR IN (text + image)
            tl.fromTo(
                sidebarText,
                { autoAlpha: 0, y: textAnimDistance },
                { autoAlpha: 1, y: 0, duration: 1 }
            )
            .fromTo(
                sidebarImage,
                { autoAlpha: 1, scale: 0.95 },
                { autoAlpha: 1, scale: 1, duration: 1 },
                "<"
            )
            // SIDEBAR OUT
            .to(sidebarText, { autoAlpha: 0, y: -textAnimDistance, duration: 1 })
            .to(sidebarImage, { autoAlpha: 0, scale: 1.05, duration: 1 }, "<")
        
            // 2) VOICE IN (text + image)
            .fromTo(
                voiceImage,
                { autoAlpha: 0, scale: 0.95 },
                { autoAlpha: 1, scale: 1, duration: 1 }
            )
            .fromTo(
                voiceText,
                { autoAlpha: 0, y: textAnimDistance },
                { autoAlpha: 1, y: 0, duration: 1 },
                "<"
            )
            // VOICE OUT
            .to(voiceText, { autoAlpha: 0, y: -textAnimDistance, duration: 1 })
            .to(voiceImage, { autoAlpha: 0, scale: 1.05, duration: 1 }, "<");
        
            // 3) RESEARCH ASSISTANT
            tl.fromTo(
                uploadFilesImage,
                { autoAlpha: 0, scale: 0.95 },
                { autoAlpha: 1, scale: 1, duration: 1 }
            )
            tl.fromTo(
                researchAssistantText,
                { opacity: 0, y: textAnimDistance },
                { opacity: 1, y: 0, duration: 1 },
                "<"
            )
            .to(researchAssistantText, { opacity: 0, y: -textAnimDistance, duration: 1 })
            .to(uploadFilesImage, { autoAlpha: 0, scale: 1.05, duration: 1 }, "<");
        
            // 4) YOUTUBE (IMAGE + TEXT)
            tl.fromTo(
                youtubeImage,
                { autoAlpha: 0, scale: 0.95 },
                { autoAlpha: 1, scale: 1, duration: 1 }
            )
            .fromTo(
                youtubeText,
                { autoAlpha: 0, y: textAnimDistance },
                { autoAlpha: 1, y: 0, duration: 1 },
                "<"
            )
            // OUT
            .to(youtubeText, { autoAlpha: 0, y: -textAnimDistance, duration: 1 })
            .to(youtubeImage, { autoAlpha: 0, scale: 1.05, duration: 1 }, "<");
        
            // 5) QUICK ACTIONS (IMAGE + TEXT)
            tl.fromTo(
                quickActionsImage,
                { autoAlpha: 0, scale: 0.95 },
                { autoAlpha: 1, scale: 1, duration: 1 }
            )
            .fromTo(
                quickActionsText,
                { autoAlpha: 0, y: textAnimDistance },
                { autoAlpha: 1, y: 0, duration: 1 },
                "<"
            )
            // OUT
            .to(quickActionsText, { autoAlpha: 0, y: -textAnimDistance, duration: 1 })
            .to(quickActionsImage, { autoAlpha: 0, scale: 1.05, duration: 1 }, "<");
        
            // 6) ARTIST (IMAGE + TEXT)
            tl.fromTo(
                artistImage,
                { autoAlpha: 0, scale: 0.95 },
                { autoAlpha: 1, scale: 1, duration: 1 }
            )
            .fromTo(
                artistText,
                { autoAlpha: 0, y: textAnimDistance },
                { autoAlpha: 1, y: 0, duration: 1 },
                "<"
            )

            {/*
            // Change the color of the copilot title to white
            tl.to(copilotTitle1, {
                color: '#f5f5f7',
                duration: 1, // Adjust as needed for smooth transition
                invalidateOnRefresh: true,
            }, '-=1') // Sync with the studio container fade-out

            // Change the color of the copilot title to white
            tl.to(copilotTitle2, {
                color: 'rgb(167 167 167)',
                duration: 1, // Adjust as needed for smooth transition
                invalidateOnRefresh: true,
            }, '-=1') // Sync with the studio container fade-out

            // Change the background color of the landing page to black
            tl.to(landingPage, {
                backgroundColor: 'black',
                duration: 1.5, // Adjust as needed for smooth transition
                invalidateOnRefresh: true,
            }, '-=1') // Sync with the studio container fade-out
            */}

            // Fade in netflix background for artist
            tl.fromTo(
                netflixBackground,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 1, // Adjust duration as needed
                },
                '-=1' // Slight overlap between the animations if needed
            );
    
            // Once the second text has fully appeared, fade out and scale the studio container
            tl.to(copilotContainer, {
                opacity: 0,
                // scale: 1.2,
                duration: 3, // Adjust as needed
            }, '+=0.25');

            /*
            // change landing page back to white
            tl.to(landingPage, {
                backgroundColor: 'white',
                duration: 1, // Adjust as needed
            }, '-=1');
            */
        }
    };

    useEffect(() => {
        let ctx = gsap.context(() => {
            initCopilotAnimation();
        }, []);
    
        return () => {
            ctx.revert();
        };
    }, [width, isPortrait]);



    // Responsize stuff
    const [copilotTitle, setCopilotTitle] = useState(
        window.innerWidth < 1099 || isPortrait ? "Copilot" : "Copilot."
    );

    // Update title based on screen width
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setCopilotTitle(window.innerWidth < 1099 || isPortrait ? "Copilot" : "Copilot.");
        };

        // Attach event listener
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, [width, isPortrait]);

    return (
        <div className="copilot-section">
            <div className="copilot-container" ref={copilotContainerRef}>
                <div className="copilot-title-wrapper">
                    <p className="feature-title" ref={copilotTitle1Ref}>
                        {copilotTitle}
                    </p>
                    <p className="feature-title-2" ref={copilotTitle2Ref}>
                        A backpack full of tools.
                    </p>
                </div>

                <ArtistShowcaseLP
                    netflixBackgroundRef={netflixBackgroundRef}
                />

                <div className="copilot-content-container">

                    <div className="copilot-image-container">
                        <img 
                            className="copilot-image" 
                            ref={copilotSidebarImageRef}
                            src={copilotSidebar} 
                            alt="Copilot Image" 
                        />
                        <img
                            className="copilot-image copilot-voice-image"
                            ref={copilotVoiceImageRef}
                            src={copilotVoice}
                            alt="Copilot Voice Feature"
                            style={{ opacity: 0 }}
                        />
                        <img
                            className="copilot-image"
                            ref={copilotUploadFilesImageRef}
                            src={copilotUploadFiles}
                            alt="Copilot Upload Files Feature"
                            style={{ opacity: 0 }}
                        />
                        <img
                            className="copilot-image"
                            ref={copilotYoutubeImageRef}
                            src={copilotYoutube}
                            alt="Copilot YouTube Feature"
                            style={{ opacity: 0 }}
                        />
                        <img
                            className="copilot-image"
                            ref={copilotQuickActionsImageRef}
                            src={copilotQuickActions}
                            alt="Copilot Quick Actions Feature"
                            style={{ opacity: 0 }}
                        />
                        <img
                            className="copilot-image"
                            ref={copilotArtistImageRef}
                            src={copilotArtist}
                            alt="Copilot Artist Feature"
                            style={{ opacity: 0 }}
                        />
                    </div>

                    {/* First set of text. Left side */}
                    <p 
                        className="copilot-scroll-text-left LP-copilot-sidebar-text" 
                        ref={copilotSidebarTextRef}
                    >
                        <span style={{display: 'block', color: 'var(--dark-text)'}}>Sidebar</span> 
                        Open your sidebar on any website for real time tutor assistance. Switch between your favourite AI models for <span style={{color: 'var(--dark-text)'}}>on-hand intelligence.</span>
                    </p>
                    <p 
                        className="copilot-scroll-text-left LP-copilot-voice-text" 
                        ref={copilotVoiceTextRef}
                    >
                        <span style={{display: 'block', color: 'var(--dark-text)'}}>Voice</span> 
                        Sick of reading? Listen instead. With the voice you chose at the beginning of your journey, <span style={{color: 'var(--dark-text)'}}>all text can be read aloud by your tutor</span>.
                    </p>
                    <p 
                        className="copilot-scroll-text-left LP-copilot-research-text" 
                        ref={copilotResearchAssistantTextRef}
                    >
                        <span style={{display: 'block', color: 'var(--dark-text)'}}>Research Assistant</span>
                        Your AI can read the current webpage and your uploaded files so you can effortlessly digest endless information. <span style={{color: 'var(--dark-text)'}}>Learn faster. Learn better.</span>
                    </p>

                    {/* Second set of text. Right side */}
                    <p 
                        className="copilot-scroll-text-right LP-copilot-youtube-text" 
                        ref={copilotYoutubeTextRef}
                    >
                        <span style={{display: 'block', color: 'var(--dark-text)'}}>YouTube Suite</span> 
                        Built directly into YouTube so you can instantly summarise videos, extract key points, and find exact moments, <span style={{color: 'var(--dark-text)'}}>saving countless hours.</span>
                    </p>
                    <p 
                        className="copilot-scroll-text-right LP-copilot-quick-actions-text" 
                        ref={copilotQuickActionsTextRef}
                    >
                        <span style={{display: 'block', color: 'var(--dark-text)'}}>Quick Actions</span>
                        Something just not sinking in? Highlight text and your tutor can explain or translate for <span style={{color: 'var(--dark-text)'}}>instant clarity</span>. Nothing's out of reach.
                    </p>
                    <p 
                        className="copilot-scroll-text-right LP-copilot-artist-text" 
                        ref={copilotArtistTextRef} 
                    >
                        <span style={{display: 'block', color: 'var(--dark-text)'}}>Artist</span>
                        Copilot has an AI model for every artistic need. Unlock the potential of <span style={{color: 'var(--dark-text)'}}>professional-quality art</span>, no matter your skill level.
                    </p>

                </div>
            </div>
        </div>
    );
}

export default CopilotLP;