import React, { useState, useEffect } from 'react';
import './HeroLP.css';

import heroImage from '../../../assets/landingPageImages/ethos-midjourney3.png';
import heroImageCenter from '../../../assets/landingPageImages/ethos-midjourney3CenteredTall.png';
import heroImageRight from '../../../assets/landingPageImages/ethos-midjourney3FarRight.png';

function HeroLP() {
    // Store portrait/landscape mode
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    
    const [heroSrc, setHeroSrc] = useState(
        isPortrait ? heroImageCenter : heroImageRight
    );

    const [heroTextTop, setHeroTextTop] = useState(
        isPortrait ? "Become who" : "Become"
    );
    const [heroTextBottom, setHeroTextBottom] = useState(
        isPortrait ? "you truly are." : "who you truly are."
    );

    // Unified function to update based on portrait/landscape mode
    const updateHeroSection = () => {
        const portrait = window.innerHeight > window.innerWidth;
        setIsPortrait(portrait);

        if (portrait) {
            setHeroSrc(heroImageCenter);
            setHeroTextTop("Become who");
            setHeroTextBottom("you truly are.");
        } else {
            setHeroSrc(heroImageRight);
            setHeroTextTop("Become");
            setHeroTextBottom("who you truly are.");
        }
    };

    useEffect(() => {
        // Run once on mount in case the screen is already in portrait mode
        updateHeroSection();

        window.addEventListener('resize', updateHeroSection);
        return () => {
            window.removeEventListener('resize', updateHeroSection);
        };
    }, []);

    return (
        <div className='hero-section-2'>
            <img
                className="hero-2-image"
                src={heroSrc}
                alt="Hero Image"
                draggable='false'
            />
            <div className="hero-2-text-container">
                <p className="hero-2-title">
                    {heroTextTop}
                </p>
                <p className="hero-2-title">
                    {heroTextBottom}
                </p>

                {/* Desktop button container (shown only in landscape mode) */}
                {!isPortrait && (
                    <div className='hero-button-container'>
                        <div className='hero-button-container-text-wrapper'>
                            <p className='hero-button-container-text-bold'>
                                Try our features for free. Cancel anytime.
                            </p>
                            <p className='hero-button-container-text'>
                                We'll remind you 7 days before your trial ends.
                            </p>
                        </div>
                        <button
                            className="hero-button landing-page-button-1 button-primary"
                            onClick={() => alert('Get started!')}
                        >
                            Accept free trial
                        </button>
                    </div>
                )}
            </div>

            {/* Mobile button container (shown only in portrait mode) */}
            {isPortrait && (
                <div className='hero-button-container hero-button-mobile'>
                    <div className='hero-button-container-text-wrapper'>
                        <p className='hero-button-container-text-bold'>
                            Try our features for free.
                        </p>
                        <p className='hero-button-container-text'>
                            Cancel anytime.
                        </p>
                    </div>
                    <button
                        className="hero-button landing-page-button-1 button-primary"
                        onClick={() => alert('Get started!')}
                    >
                        Accept free trial
                    </button>
                </div>
            )}

        </div>
    );
}
    
export default HeroLP;