import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './StudioLP.css';
import studioPlaceHolderImage from '../../../assets/landingPageImages/macbook-backgroundless.png';

gsap.registerPlugin(ScrollTrigger);

function StudioLP({ landingPageRef, mindmapTitleContainerRef, mindmapCanvasRef }) {
    // Store width
    const [width, setWidth] = useState(window.innerWidth);
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    
    const studioContainerRef = useRef(null);
    const firstTextElementRef = useRef(null);
    const secondTextElementRef = useRef(null);

    // GSAP animation for studio section
    const initStudioAnimation = () => {
        const landingPage = landingPageRef.current;
        const studioContainer = studioContainerRef.current;
        const firstTextElement = firstTextElementRef.current;
        const secondTextElement = secondTextElementRef.current;
        const mindmapTitleContainer = mindmapTitleContainerRef.current;
        const mindmapCanvas = mindmapCanvasRef.current;

        // Decide the animation's 'y' transition value based on width and orientation
        const textAnimDistance = width < 1099 || isPortrait ? 0 : 100;
    
        if (
            studioContainer 
            && firstTextElement 
            && secondTextElement 
            && mindmapCanvas 
            && mindmapTitleContainer
        ) {
            // Pin the studio-container until the second text animation is done
            ScrollTrigger.create({
                trigger: studioContainer,
                start: 'top top+=5%', // Pin when 5% from top
                end: () => `+=${window.innerHeight * 2}`, // Pin until the second text has finished
                pin: true,
                scrub: true,
                pinSpacing: false,
                // markers: true, // Uncomment for debugging
            });
    
            // Create a GSAP timeline to synchronize animations
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: studioContainer, // Sync animations to the pinning of the studio container
                    start: 'top top+=5%', // Start when the studio container is pinned
                    end: '+=100%', // Duration for both text animations
                    scrub: true,
                    // markers: true, // Uncomment for debugging
                },
            });
    
            // First text element animation (fade in, move up, fade out)
            tl.fromTo(
                firstTextElement,
                { opacity: 0, y: textAnimDistance },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1, // Adjust duration as needed
                }
            )
            .to(firstTextElement, {
                opacity: 0,
                y: -textAnimDistance, // Move up while fading out
                duration: 1, // Adjust duration as needed
            });
    
            // Second text element animation (fade in after first text has finished)
            tl.fromTo(
                secondTextElement,
                { opacity: 0, y: textAnimDistance },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1, // Adjust duration as needed
                },
                '-=0.0' // Slight overlap between the animations if needed
            );
    
            // Once the second text has fully appeared, fade out and scale the studio container
            tl.to(studioContainer, {
                opacity: 0,
                scale: 1.2,
                duration: 1, // Adjust as needed
            })

            // Change the background color of the landing page to black
            .to(landingPage, {
                backgroundColor: 'black',
                duration: 1.5, // Adjust as needed for smooth transition
                invalidateOnRefresh: true,
            }, '-=1') // Sync with the studio container fade-out

            // Change the mindmap elements' z-indexes
            .fromTo(
                mindmapTitleContainer,
                { zIndex: -1 }, // Initial state
                {
                    zIndex: 1, // Fade in
                    duration: 0.1,
                }, '-=0.5'
            )

            .fromTo(
                mindmapCanvas,
                { zIndex: -1 }, // Initial state
                {
                    zIndex: 1, // Fade in
                    duration: 0.1,
                }, '-=0.1'
            )

            // Fade in mind-map-title-container and scale it
            .fromTo(
                mindmapTitleContainer,
                { opacity: 0, scale: 0.8 }, // Initial state
                {
                    opacity: 1, // Fade in
                    scale: 1, // Bring to normal size
                    duration: 1.5, // Adjust duration as needed
                }
            )

            // Shrink and fade out mind-map-title-container
            .to(
                mindmapTitleContainer,
                {
                    opacity: 0,
                    scale: 1.2, // Shrink down
                    duration: 1.5, // Adjust duration as needed
                }
            )

            // Fade in mind-map canvas and change z-index
            .fromTo(
                mindmapCanvas,
                { opacity: 0 }, // Initial state
                {
                    opacity: 1, // Fade in
                    duration: 1.5, // Adjust duration as needed
                    invalidateOnRefresh: true,
                },
                '-=1' // Slight overlap with mind-map-title-container fade-out if needed
            );
        }
    };

    useEffect(() => {
        let ctx = gsap.context(() => {
            initStudioAnimation();
        }, []);
    
        return () => {
            ctx.revert();
        };
    }, [width, isPortrait]);




    // Responsize stuff
    const [studioTitle, setStudioTitle] = useState(
        window.innerWidth < 1099 || isPortrait ? "Studio" : "Studio."
    );

    // Update title based on screen width
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setStudioTitle(window.innerWidth < 1099 || isPortrait ? "Studio" : "Studio.");
        };

        // Attach event listener
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on unmount
        return () => window.removeEventListener("resize", handleResize);
    }, [width, isPortrait]);

    return (
        <div className="studio-section">
            <div className="studio-container" ref={studioContainerRef}>
                <div className="studio-title-wrapper">
                    <p className="feature-title">
                        {studioTitle}
                    </p>
                    <p className="feature-title-2">
                        Knowledge consolidation.
                    </p>
                </div>

                <div className="studio-content-container">
                    <div className="studio-image-container">
                        <img className="studio-image" src={studioPlaceHolderImage} alt="Studio Image" />
                    </div>

                    {/* First text element */}
                    <div className="studio-text-element">
                        <p 
                            className="studio-scroll-text LP-studio-imposter-text" 
                            ref={firstTextElementRef}
                        >
                            <span style={{display: 'block', color: 'var(--dark-text)'}}>
                                Cure imposter syndrome.
                            </span> 
                            Instantly create quizzes on any topic, at any skill level. Helping you build confidence in what you know.
                        </p>
                        <p 
                            className="studio-scroll-text LP-studio-agile-text" 
                            ref={secondTextElementRef}
                        >
                            <span style={{display: 'block', color: 'var(--dark-text)'}}>
                                Build an agile mind.
                            </span>
                            Five quiz formats to attack your mind five different ways. Get addicted to levelling-up.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudioLP;