import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import './MultipleChoiceResult.css';

function MultipleChoiceResult({ questionData, difficulty }) {
  const containerRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      containerRef.current,
      { scale: 0.5, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
    );
  }, []);

  const isCorrect = questionData.userAnswer === questionData.answer;

  return (
    <div className="mc-result" ref={containerRef}>
      {/* Header */}
      <div className="mc-result__header">
        <h2>Multiple Choice</h2>
      </div>

      {/* Question Text */}
      <div className="mc-result__question">
        {questionData.question}
      </div>

      {/* Body: Options & Explanation */}
      <div className="mc-result__body">
        <div className="mc-result__options">
          {Object.entries(questionData.choices).map(([key, optionText]) => {
            const isUserChoice = questionData.userAnswer === key;
            const isCorrectChoice = questionData.answer === key;
            let optionClass = "mc-result__option";
            // Append difficulty modifier class for options using one hyphen:
            optionClass += ` mc-result__option-${difficulty}`;

            if (isUserChoice && !isCorrect) {
              optionClass += " mc-result__option--selected-wrong";
            } else if (isCorrectChoice) {
              optionClass += " mc-result__option--correct";
            } else if (isUserChoice && isCorrect) {
              optionClass += " mc-result__option--selected-correct";
            }
            return (
              <div key={key} className={optionClass}>
                <span className="mc-result__option-key">{key}.</span> {optionText}
              </div>
            );
          })}
        </div>
        <div className="mc-result__explanation">
          <p>{questionData.explanation}</p>
        </div>
      </div>
    </div>
  );
}

export default MultipleChoiceResult;
