import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import './TrueFalseResult.css';

function TrueFalseResult({ questionData, difficulty }) {
  const containerRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      containerRef.current,
      { scale: 0.5, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
    );
  }, []);

  // Determine whether the answer is correct.
  const isCorrect = questionData.userAnswer === questionData.answer;

  // Prepare classes for True and False options.
  let trueOptionClass = "tf-result__option";
  let falseOptionClass = "tf-result__option";
  if (difficulty) {
    trueOptionClass += ` tf-result__option-${difficulty}`;
    falseOptionClass += ` tf-result__option-${difficulty}`;
  }
  // For True option:
  if (questionData.userAnswer === "True" && questionData.answer !== "True") {
    trueOptionClass += " tf-result__option--selected-wrong";
  } else if (questionData.answer === "True") {
    trueOptionClass += " tf-result__option--correct";
  } else if (questionData.userAnswer === "True" && questionData.answer === "True") {
    trueOptionClass += " tf-result__option--selected-correct";
  }
  // For False option:
  if (questionData.userAnswer === "False" && questionData.answer !== "False") {
    falseOptionClass += " tf-result__option--selected-wrong";
  } else if (questionData.answer === "False") {
    falseOptionClass += " tf-result__option--correct";
  } else if (questionData.userAnswer === "False" && questionData.answer === "False") {
    falseOptionClass += " tf-result__option--selected-correct";
  }

  return (
    <div className="tf-result" ref={containerRef}>
      {/* Header */}
      <div className="tf-result__header">
        <h2>True or False</h2>
      </div>
      
      {/* Question Text */}
      <div className="tf-result__question">
        {questionData.question}
      </div>
      
      {/* Options */}
      <div className="tf-result__options">
        <div className={trueOptionClass}>
          <span className="tf-result__option-key">True</span>
        </div>
        <div className={falseOptionClass}>
          <span className="tf-result__option-key">False</span>
        </div>
      </div>
      
      {/* Explanation */}
      <div className="tf-result__explanation">
        <p>{questionData.explanation}</p>
      </div>
    </div>
  );
}

export default TrueFalseResult;
