import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import './PricingLP.css';

import fileAnalysisVideo from '../../../assets/videos/File Analysis Video Preview.mp4';
import readPageVideo from '../../../assets/videos/Read Page Video Preview.mp4';
import youtubeVideo from '../../../assets/videos/Youtube Video Preview.mp4';


function PricingLP() {
    const plansTitleRef = useRef(null);
    const novicePlanCardRef = useRef(null);
    const proPlanCardRef = useRef(null);
    const ultraPlanCardRef = useRef(null);

    const fileAnalysisVideoRef = useRef(null);
    const readPageVideoRef = useRef(null);
    const youtubeVideoRef = useRef(null);

    // Load in animations
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const delay = entry.target.getAttribute('data-animation-delay') || 0;
    
                        // GSAP timeline to animate with staggered/overlapping animations
                        const tl = gsap.timeline({
                            defaults: { duration: 1, ease: 'power2.out' }
                        });
    
                        // GSAP animation for each target with delay from data attribute
                        tl.fromTo(
                            entry.target,
                            { opacity: 0, y: 20 }, // Starting state
                            { opacity: 1, y: 0 },   // Final state
                            `+=${delay}` // Use the delay from the data attribute
                        );
    
                        // Stop observing the target after the animation
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.2,  // Trigger when 50% of the element is visible
            }
        );

        // Ref for elements
        const refs = [
            plansTitleRef, 
            novicePlanCardRef, 
            proPlanCardRef, 
            ultraPlanCardRef
        ];
        
        refs.forEach(ref => {
            if (ref.current) observer.observe(ref.current);
        });
    
        return () => {
            refs.forEach(ref => {
                if (ref.current) observer.unobserve(ref.current);
            });
        };
    }, []);


    // Play videos on hover
    const handleHover = (videoRef) => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0; // start at beginning
            videoRef.current.play();
        }
    };
      
    const handleHoverOut = (videoRef) => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };
    
    return (
        <div className="plans-section">
            <p ref={plansTitleRef} className="plans-section-title">
                Become the main character.
            </p>

            <div className="plan-table-container">

                <div 
                    ref={novicePlanCardRef} 
                    className="plan-card novice-plan-card" 
                    data-animation-delay="0"
                >
                    <div className="plan-card-top-section">
                        <div className="plan-text-container">
                            <p className="plan-title novice-title">
                                Novice
                            </p>
                            <p className="plan-body">
                                Limited access. If you're new to Paradigm, we recommend starting with the Pro plan's 14-day trial.
                            </p>
                        </div>
                        <div className="price-and-button-container">
                            <div className="plan-price-container">
                                <p className="plan-price">£0</p>
                                <div className="per-month-container">
                                    <p className="per-month-text">per</p>
                                    <p className="per-month-text">month</p>
                                </div>
                            </div>
                            <button className="button-primary plan-button" onClick={() => alert('Novice!')}>
                                Subscribe
                            </button>
                        </div>
                    </div>
                    <div className="plan-includes-container">

                        <span className="product-subheading">
                            Daily Tokens
                        </span>

                        <div className="includes-item">
                            <i className="fa-solid fa-coins LP-pricing-tokens-icon"></i>
                            <span className="includes-text"> 
                                <span>1,000</span> - Spent when using features. Tokens reset daily.
                            </span>
                        </div>

                        <span className='product-subheading'>
                            Copilot
                        </span>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text"> 
                                <span>Standard Models</span> - GPT 3.5, Claude 3 Haiku, & Gemini 1.5 Flash.
                            </span>
                        </div>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <span className="includes-text">
                                <span>Advanced models</span> - GPT-4o, Claude 3.5 Sonnet, & Gemini 1.5 Pro.
                            </span>
                        </div>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <div>
                                <span className="includes-text"> 
                                    <span>Research Assistant</span> - File Analysis, Read Page, & Enhanced Search.
                                </span>
                            </div>
                        </div>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <div>
                                <span className="includes-text">
                                    <span>YouTube Suite</span> - Video summaries, key points, & Moment Finder. 
                                </span>
                            </div>
                        </div>
                        
                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <span className="includes-text"> 
                                <span>Paradigm Artist</span> - Standard & advanced AI image creation options.
                            </span>
                        </div>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <span className="includes-text"> 
                                <span>Voice activations</span> - Equip your tutor can with one of six different voices.
                            </span>
                        </div>

                        <span className='product-subheading LP-no-access-feature'>
                            Studio
                        </span>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <span className="includes-text"> 
                                <span>Instant knowledge validation</span> - Generate a quiz on any topic to boost confidence.
                            </span>
                        </div>

                        <div class="includes-item LP-no-access-feature">
                            <i class="bi bi-x-circle LP-no-access-x"></i>
                            <span class="includes-text"> 
                                <span>Cure imposter syndrome</span> - Set difficulty level to progress from Novice to Master.
                            </span>
                        </div>

                        <div class="includes-item LP-no-access-feature">
                            <i class="bi bi-x-circle LP-no-access-x"></i>
                            <span class="includes-text">
                                <span>Build an agile mind</span> - Five quiz formats to attack your mind five different ways.
                            </span>
                        </div>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <span className="includes-text">
                                <span>Consolidate your learning</span> - Revisit quizes with quiz history. Progress is what matters.
                            </span>
                        </div>

                        <span className='product-subheading LP-no-access-feature'>
                            Mind Map
                        </span>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <span className="includes-text">
                                <span>Visualise your mind</span> - Explore a universe unique to you in stunning 3D.
                            </span>
                        </div>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <span className="includes-text">
                                <span>Skill trees that are alive and organic</span> - Form learning pathways that grow as you grow.
                            </span>
                        </div>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <span className="includes-text">
                                <span>Incentives & Rewards</span> - Gain XP, level up, play mini games, and earn rewards.
                            </span>
                        </div>

                        <div className="includes-item LP-no-access-feature">
                            <i className="bi bi-x-circle LP-no-access-x"></i>
                            <span className="includes-text">
                                <span>Community & Leaderboards</span> - Compete with other players and see where you rank.
                            </span>
                        </div>

                    </div>
                </div>

                <div 
                    ref={proPlanCardRef} 
                    className="plan-card LP-pro-plan-card" 
                    data-animation-delay="0.2"
                >
                    <div className="LP-plan-badge-container">
                        <span className="LP-plan-most-popular-text"> Most popular </span>
                    </div>
                    
                    <div className="plan-card-top-section">
                        <div className="plan-text-container">
                            <p className="plan-title pro-title">
                                Pro - Free Trial
                            </p>
                            <p className="plan-body">
                                Try our full suite of features for free. Cancel anytime. We'll remind you 7 days before your trial ends.
                            </p>
                        </div>
                        <div className="price-and-button-container">
                            <div className="plan-price-container">
                                <p className="plan-price">£9.99</p>
                                <div className="per-month-container">
                                    <p className="per-month-text">per</p>
                                    <p className="per-month-text">month</p>
                                </div>
                            </div>
                            <button className="button-primary plan-button" onClick={() => alert('Pro!')}>
                                Start my free trial
                            </button>
                        </div>
                    </div>

                    <div className="plan-includes-container">

                        <span className="product-subheading">
                            Daily Tokens
                        </span>

                        <div className="includes-item">
                            <i className="fa-solid fa-coins LP-pricing-tokens-icon"></i>
                            <span className="includes-text"> 
                                <span>1,000</span> - Spent when using features. Tokens reset daily.
                            </span>
                        </div>

                        <span className='product-subheading'>
                            Copilot
                        </span>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text"> 
                                <span>Standard Models</span> - GPT 3.5, Claude 3 Haiku, & Gemini 1.5 Flash.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Advanced models</span> - GPT-4o, Claude 3.5 Sonnet, & Gemini 1.5 Pro.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <div>
                                <span className="includes-text"> <span>Research Assistant</span> - File Analysis </span>
                                <span className="extra-feature-info-file-analysis-container">
                                    <div 
                                        className="file-analysis-wrapper"
                                        onMouseEnter={() => handleHover(fileAnalysisVideoRef)}
                                        onMouseLeave={() => handleHoverOut(fileAnalysisVideoRef)}
                                    >
                                        <div className="file-analysis-feature-video">
                                            <video 
                                                ref={fileAnalysisVideoRef} 
                                                className="file-analysis-feature-video-element" 
                                                width="100%" 
                                                height="100%" 
                                                muted 
                                                loop
                                            >
                                                <source src={fileAnalysisVideo} type="video/mp4"/>
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <i className="bi bi-info-circle-fill extra-feature-info"></i>
                                    </div>
                                </span>
                                <span className="includes-text">, Read Page </span>
                                <span className="extra-feature-info-webpage-summaries-container">
                                    <div 
                                        className="webpage-summaries-wrapper"
                                        onMouseEnter={() => handleHover(readPageVideoRef)}
                                        onMouseLeave={() => handleHoverOut(readPageVideoRef)}
                                    >
                                        <div className="webpage-summaries-feature-video">
                                            <video 
                                                ref={readPageVideoRef} 
                                                className="webpage-summaries-feature-video-element" 
                                                width="100%" 
                                                height="100%" 
                                                muted 
                                                loop
                                            >
                                                <source src={readPageVideo} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <i className="bi bi-info-circle-fill extra-feature-info"></i>
                                    </div>
                                </span>
                                <span className="includes-text">
                                    , & Enhanced Search.
                                </span>
                            </div>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <div>
                                <span className="includes-text"><span>YouTube Suite</span> - Video summaries, key points, & Moment Finder. </span>
                                <div 
                                    className="extra-feature-info-youtube-container"
                                    onMouseEnter={() => handleHover(youtubeVideoRef)}
                                    onMouseLeave={() => handleHoverOut(youtubeVideoRef)}
                                >
                                    <div className="youtube-video-wrapper">
                                        <div className="youtube-feature-video">
                                            <video 
                                                ref={youtubeVideoRef} 
                                                className="youtube-feature-video-element" 
                                                width="100%" 
                                                height="100%" 
                                                muted 
                                                loop
                                            >
                                                <source src={youtubeVideo} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <i className="bi bi-info-circle-fill extra-feature-info"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text"> 
                                <span>Paradigm Artist</span> - Standard & advanced AI image creation options.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text"> 
                                <span>Voice activations</span> - Equip your tutor can with one of six different voices.
                            </span>
                        </div>

                        <span className='product-subheading'>
                            Studio
                        </span>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text"> 
                                <span>Instant knowledge validation</span> - Generate a quiz on any topic to boost confidence.
                            </span>
                        </div>

                        <div class="includes-item">
                            <i class="bi bi-check-lg LP-pricing-tick"></i>
                            <span class="includes-text"> 
                                <span>Cure imposter syndrome</span> - Set difficulty level to progress from Novice to Master.
                            </span>
                        </div>

                        <div class="includes-item">
                            <i class="bi bi-check-lg LP-pricing-tick"></i>
                            <span class="includes-text">
                                <span>Build an agile mind</span> - Five quiz formats to attack your mind five different ways.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Consolidate your learning</span> - Revisit quizes with quiz history. Progress is what matters.
                            </span>
                        </div>

                        <span className='product-subheading'>
                            Mind Map
                        </span>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Visualise your mind</span> - Explore a universe unique to you in stunning 3D.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Skill trees that are alive and organic</span> - Form learning pathways that grow as you grow.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Incentives & Rewards</span> - Gain XP, level up, play mini games, and earn rewards.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Community & Leaderboards</span> - Compete with other players and see where you rank.
                            </span>
                        </div>

                    </div>
                </div>

                <div 
                    ref={ultraPlanCardRef} 
                    className="plan-card ultra-plan-card" 
                    data-animation-delay="0.4"
                >
                    <div className="plan-card-top-section">
                        <div className="plan-text-container">
                            <p className="plan-title ultra-title">Ultra</p>
                            <p className="plan-body">Paradigm at its very best. Maximum power. Maximum productivity.</p>
                        </div>
                        <div className="price-and-button-container">
                            <div className="plan-price-container">
                                <p className="plan-price">£24.99</p>
                                <div className="per-month-container">
                                    <p className="per-month-text">per</p>
                                    <p className="per-month-text">month</p>
                                </div>
                            </div>
                            <button className="button-primary plan-button" onClick={() => alert('Ultra!')}>
                                Subscribe
                            </button>
                        </div>
                    </div>
                    <div className="plan-includes-container">

                    <span className="product-subheading">
                            Daily Tokens
                        </span>

                        <div className="includes-item">
                            <i className="fa-solid fa-coins LP-pricing-tokens-icon"></i>
                            <span className="includes-text"> 
                                <span>2,500</span> - Spent when using features. Tokens reset daily.
                            </span>
                        </div>

                        <span className='product-subheading'>
                            Copilot
                        </span>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text"> 
                                <span>Standard Models</span> - GPT 3.5, Claude 3 Haiku, & Gemini 1.5 Flash.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Advanced models</span> - GPT-4o, Claude 3.5 Sonnet, & Gemini 1.5 Pro.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <div>
                                <span className="includes-text"> 
                                    <span>Research Assistant</span> - File Analysis, Read Page, & Enhanced Search.
                                </span>
                            </div>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <div>
                                <span className="includes-text">
                                    <span>YouTube Suite</span> - Video summaries, key points, & Moment Finder. 
                                </span>
                            </div>
                        </div>
      
                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text"> 
                                <span>Paradigm Artist</span> - Standard & advanced AI image creation options.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text"> 
                                <span>Voice activations</span> - Equip your tutor can with one of six different voices.
                            </span>
                        </div>

                        <span className='product-subheading'>
                            Studio
                        </span>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text"> 
                                <span>Instant knowledge validation</span> - Generate a quiz on any topic to boost confidence.
                            </span>
                        </div>

                        <div class="includes-item">
                            <i class="bi bi-check-lg LP-pricing-tick"></i>
                            <span class="includes-text"> 
                                <span>Cure imposter syndrome</span> - Set difficulty level to progress from Novice to Master.
                            </span>
                        </div>

                        <div class="includes-item">
                            <i class="bi bi-check-lg LP-pricing-tick"></i>
                            <span class="includes-text">
                                <span>Build an agile mind</span> - Five quiz formats to attack your mind five different ways.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Consolidate your learning</span> - Revisit quizes with quiz history. Progress is what matters.
                            </span>
                        </div>

                        <span className='product-subheading'>
                            Mind Map
                        </span>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Visualise your mind</span> - Explore a universe unique to you in stunning 3D.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Skill trees that are alive and organic</span> - Form learning pathways that grow as you grow.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Incentives & Rewards</span> - Gain XP, level up, play mini games, and earn rewards.
                            </span>
                        </div>

                        <div className="includes-item">
                            <i className="bi bi-check-lg LP-pricing-tick"></i>
                            <span className="includes-text">
                                <span>Community & Leaderboards</span> - Compete with other players and see where you rank.
                            </span>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default PricingLP;