import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import ClassroomWindow from './ClassroomWindow';
import { useSidebar } from '../Sidebar/SidebarContext';
import { useAuth } from '../helpers/AuthProvider';
import { ReactComponent as ChatIcon } from '../../assets/images/sidebar-svgrepo-com.svg';
import { ReactComponent as NewChatIcon } from '../../assets/images/new-svgrepo-com.svg';
import { gsap } from 'gsap';

import './Classroom.css';
import '../Sidebar/Sidebar.css';

function Classroom() {
  const { id: classroomId } = useParams();
  const { setSidebarContent, refreshSidebarFlag, refreshSidebar } = useSidebar();
  const { token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // Sidebar state
  const [isSidebarVisible, setIsSidebarVisible] = useState(
    JSON.parse(localStorage.getItem('sidebarVisible')) ?? true
  );
  const [showSidebarTooltip, setShowSidebarTooltip] = useState(false);
  const [showNewChatTooltip, setShowNewChatTooltip] = useState(false);

  // Classroom history state
  const [classroomHistory, setClassroomHistory] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);
  const [typingTitleClassroomId, setTypingTitleClassroomId] = useState(null);

  // Refs for animation and sidebar content container
  const animationRef = useRef(null);
  const sidebarContentRef = useRef(null);

  // For orchestrating switching views
  const [activeView, setActiveView] = useState('data'); // can be 'data' or 'chat'
  const prevActiveView = useRef(null);
  const initialAnimationDone = useRef(false);

  // Fetch all classrooms from the backend by iterating through pages.
  const fetchAllClassrooms = async () => {
    if (!token) {
      console.error('User is not authenticated.');
      return;
    }
    try {
      // Hide the elements if sidebar exists.
      const historyContent = sidebarContentRef.current?.querySelector('.sidebar-history-content');
      if (historyContent) {
        const elements = historyContent.querySelectorAll('.history-item, .history-heading');
        elements.forEach((el) => (el.style.opacity = '0'));
      }
      setIsHistoryLoading(true);
      let allClassrooms = [];
      let url = '/api/classrooms/';
      let page = 1;
      // Loop until there are no more pages.
      while (url) {
        console.log(`Fetching page ${page} from URL: ${url}`);
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          console.error(`Failed to fetch page ${page}:`, response.statusText);
          break;
        }
        const data = await response.json();
        if (data.results) {
          console.log(`Page ${page}: received ${data.results.length} classrooms.`);
          allClassrooms = allClassrooms.concat(data.results);
          url = data.next; // data.next is null if no more pages.
        } else {
          console.log(`Non-paginated response received with ${data.length} classrooms.`);
          allClassrooms = data;
          url = null;
        }
        page++;
      }
      console.log(`Total classrooms fetched: ${allClassrooms.length}`);
      setClassroomHistory(allClassrooms);
      if (allClassrooms.length > 0 && location.state?.fromCreateQuiz) {
        const latestClassroom = allClassrooms[0];
        setTypingTitleClassroomId(latestClassroom.classroom_id);
        setTimeout(() => {
          if (latestClassroom.title) {
            typeOutTitle(latestClassroom.classroom_id, latestClassroom.title);
          }
        }, 100);
      } else {
        setTypingTitleClassroomId(null);
      }
    } catch (error) {
      console.error('Error fetching classroom history:', error.message);
    } finally {
      setIsHistoryLoading(false);
    }
  };

  const localRefreshSidebar = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    await fetchAllClassrooms();
  };

  // Handle New Quiz: Clear current draft and navigate with a new unique ID.
  const handleNewQuiz = () => {
    if (!token) {
      console.error('User is not authenticated.');
      return;
    }
    localStorage.removeItem(`quizDraft_${classroomId}`);
    const newUniqueId = uuidv4();
    navigate(`/studio/classroom/create/${newUniqueId}`, { state: { fromCreateQuiz: true } });
  };

  // Group classroom history by date and return an array of group objects.
  const groupByDate = (items) => {
    const today = new Date();
    const groups = [];
    const groupMap = {
      'Today': [],
      'Yesterday': [],
      'Previous 7 Days': [],
      'Previous 30 Days': [],
    };
    // For items older than 30 days, group by month and year.
    const older = {};
    items.forEach((item) => {
      const createdAt = new Date(item.created_at);
      const diffInDays = Math.floor((today - createdAt) / (1000 * 60 * 60 * 24));
      if (diffInDays === 0) {
        groupMap['Today'].push(item);
      } else if (diffInDays === 1) {
        groupMap['Yesterday'].push(item);
      } else if (diffInDays <= 7) {
        groupMap['Previous 7 Days'].push(item);
      } else if (diffInDays <= 30) {
        groupMap['Previous 30 Days'].push(item);
      } else {
        const monthYear = createdAt.toLocaleString('default', { month: 'long', year: 'numeric' });
        if (!older[monthYear]) {
          older[monthYear] = [];
        }
        older[monthYear].push(item);
      }
    });
    // Convert groupMap to an array of group objects.
    Object.entries(groupMap).forEach(([label, items]) => {
      if (items.length > 0) {
        groups.push({ label, items });
      }
    });
    Object.entries(older).forEach(([monthYear, items]) => {
      groups.push({ label: monthYear, items });
    });
    return groups;
  };

  // Typing effect for classroom title.
  const typeOutTitle = (classroomId, title) => {
    const element = document.getElementById(`classroom-title-${classroomId}`);
    if (!element) return;
    let typedTitle = '';
    let index = 0;
    const interval = setInterval(() => {
      if (index < title.length) {
        typedTitle += title[index];
        element.textContent = typedTitle;
        index++;
      } else {
        clearInterval(interval);
      }
    }, 30);
  };

  /////////////////////////////////
  // Animation Functions
  /////////////////////////////////
  const killAllAnimations = () => {
    if (animationRef.current) {
      animationRef.current.kill();
      animationRef.current = null;
    }
    gsap.killTweensOf('.history-item');
  };

  const waveOutSidebar = () => {
    return new Promise((resolve) => {
      killAllAnimations();
      const waveOutTl = gsap.timeline({
        onComplete: () => {
          const sidebarContainer = sidebarContentRef.current?.querySelector('.sidebar-history-content');
          if (sidebarContainer) {
            sidebarContainer.style.display = 'none';
          }
          resolve();
        },
      });
      const oldGroups = sidebarContentRef.current?.querySelectorAll('.history-group') || [];
      oldGroups.forEach((group) => {
        const headingEl = group.querySelector('.history-heading');
        if (headingEl) {
          waveOutTl.to(headingEl, { autoAlpha: 0, scale: 0.8, duration: 0.2, ease: 'power2.out' });
        }
        const itemEls = Array.from(group.querySelectorAll('.history-item'));
        const visibleSet = itemEls.slice(0, 10);
        const remainingItems = itemEls.slice(10);
        waveOutTl.to(
          visibleSet,
          { autoAlpha: 0, scale: 0.8, duration: 0.25, ease: 'power2.out', stagger: 0.02 },
          '-=0.25'
        );
        if (remainingItems.length > 0) {
          waveOutTl.to(remainingItems, { autoAlpha: 0, duration: 0.2, ease: 'power2.out' }, '-=0.15');
        }
      });
      animationRef.current = waveOutTl;
      return waveOutTl;
    });
  };

  const waveInSidebar = (animate = true) => {
    return new Promise((resolve) => {
      killAllAnimations();
      const sidebarContainer = sidebarContentRef.current?.querySelector('.sidebar-history-content');
      if (sidebarContainer) {
        sidebarContainer.style.display = 'flex';
        // Get all groups and items, then hide them.
        const newGroups = Array.from(sidebarContainer.querySelectorAll('.history-group'));
        const allItems = [];
        newGroups.forEach((group) => {
          const headingEl = group.querySelector('.history-heading');
          if (headingEl) {
            allItems.push(headingEl);
          }
          const itemEls = Array.from(group.querySelectorAll('.history-item'));
          allItems.push(...itemEls);
        });

        if (animate) {
          gsap.set(allItems, { autoAlpha: 0, scale: 0.7 });
        } else {
          gsap.set(allItems, { autoAlpha: 1, scale: 1 });
        }
      }
      if (animate) {
        const waveInTl = gsap.timeline({ onComplete: resolve });
        const newGroups = Array.from(sidebarContainer?.querySelectorAll('.history-group') || []);
        const allItems = [];
        newGroups.forEach((group) => {
          const headingEl = group.querySelector('.history-heading');
          if (headingEl) {
            allItems.push(headingEl);
          }
          const itemEls = Array.from(group.querySelectorAll('.history-item'));
          allItems.push(...itemEls);
        });
        waveInTl.to(
          allItems,
          { autoAlpha: 1, scale: 1, duration: 0.4, ease: 'back.out(3)', stagger: 0.05 }
        );
        animationRef.current = waveInTl;
      } else {
        resolve();
      }
    });
  };

  /////////////////////////////////
  // ORCHESTRATE SWITCHING VIEWS
  /////////////////////////////////
  useEffect(() => {
    if (isHistoryLoading || classroomHistory.length === 0) return;

    if (!prevActiveView.current) {
      waveInSidebar().then(() => {
        initialAnimationDone.current = true;
      });
      prevActiveView.current = activeView;
      return;
    }
    if (prevActiveView.current !== activeView) {
      waveOutSidebar().then(() => {
        waveInSidebar();
      });
      prevActiveView.current = activeView;
    }
  }, [activeView, isHistoryLoading, classroomHistory]);

  /////////////////////////////////
  // Animate Sidebar History on Change
  /////////////////////////////////
  useEffect(() => {
    if (sidebarContentRef.current) {
      killAllAnimations();
      waveInSidebar();
    }
  }, [classroomHistory]);

  /////////////////////////////////
  // Set Sidebar Content with Animated History
  /////////////////////////////////
  useEffect(() => {
    if (isSidebarVisible) {
      setSidebarContent(
        <div ref={sidebarContentRef}>
          <div className="sidebar-content">
            <div className="sidebar-icon-container">
              <div
                className="sidebar-close-icon-container"
                onMouseEnter={() => setShowSidebarTooltip(true)}
                onMouseLeave={() => setShowSidebarTooltip(false)}
                onClick={toggleSidebarVisibility}
              >
                <ChatIcon className="sidebar-icon chat-svg-icon" />
                {showSidebarTooltip && (
                  <div className="sidebar-tooltip fade-in" onMouseEnter={() => setShowSidebarTooltip(false)}>
                    Close Sidebar
                  </div>
                )}
              </div>
              <div
                className="chat-icon-container"
                onMouseEnter={() => setShowNewChatTooltip(true)}
                onMouseLeave={() => setShowNewChatTooltip(false)}
                onClick={handleNewQuiz}
              >
                <NewChatIcon className="sidebar-icon settings-svg-icon" />
                {showNewChatTooltip && (
                  <div className="chat-tooltip fade-in" onMouseEnter={() => setShowNewChatTooltip(false)}>
                    New Quiz
                  </div>
                )}
              </div>
            </div>
            <div className="sidebar-divider"></div>
            <div className="sidebar-history-content">
              {classroomHistory.length > 0 ? (
                renderGroupedClassrooms(groupByDate(classroomHistory))
              ) : isHistoryLoading ? (
                <div className="loading-spinner">
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                <p>
                  It’s a fresh start! Create your first quiz and begin stacking up those achievements!
                </p>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      setSidebarContent('');
    }
  }, [
    isSidebarVisible,
    setSidebarContent,
    showSidebarTooltip,
    showNewChatTooltip,
    classroomHistory,
    isHistoryLoading,
  ]);

  useEffect(() => {
    localStorage.setItem('sidebarVisible', JSON.stringify(isSidebarVisible));
  }, [isSidebarVisible]);

  useEffect(() => {
    fetchAllClassrooms();
  }, [token]);

  useEffect(() => {
    fetchAllClassrooms();
  }, [refreshSidebarFlag]);

  useEffect(() => {
    if (typingTitleClassroomId) {
      const latestClassroom = classroomHistory.find(
        (classroom) => classroom.classroom_id === typingTitleClassroomId
      );
      if (latestClassroom && latestClassroom.title) {
        typeOutTitle(latestClassroom.classroom_id, latestClassroom.title);
      }
    }
  }, [typingTitleClassroomId, classroomHistory]);

  useEffect(() => {
    const logoContainer = document.getElementById('studio-logo-container');
    if (logoContainer) {
      logoContainer.classList.add('studio-logo-hidden');
    }
    return () => {
      if (logoContainer) {
        logoContainer.classList.remove('studio-logo-hidden');
      }
    };
  }, []);

  // NEW: useEffect to handle DOM manipulation after isSidebarVisible changes.
  // Instead of relying solely on requestAnimationFrame, we recursively check for the ref.
  useEffect(() => {
    console.log("useEffect triggered for isSidebarVisible:", isSidebarVisible);
    if (isSidebarVisible) {
      const maxRetries = 5;
      let attempt = 0;
      const checkSidebarContent = () => {
        attempt++;
        if (sidebarContentRef.current) {
          console.log("sidebarContentRef.current exists:", sidebarContentRef.current);
          const historyContent = sidebarContentRef.current.querySelector('.sidebar-history-content');
          if (historyContent) {
            console.log("Found historyContent element:", historyContent);
            historyContent.style.display = 'flex';
            historyContent.classList.add('open');
            console.log("Added 'open' class. Current classList:", historyContent.classList);
            const elements = historyContent.querySelectorAll('.history-item, .history-heading');
            console.log("Found elements to reset inline styles:", elements);
            elements.forEach((el, idx) => {
              console.log(
                `Before resetting element ${idx}: opacity: ${el.style.opacity}, transform: ${el.style.transform}`
              );
              el.style.removeProperty('opacity');
              el.style.removeProperty('transform');
              console.log(
                `After resetting element ${idx}: opacity: ${el.style.opacity}, transform: ${el.style.transform}`
              );
            });
          } else {
            console.log("No historyContent element found in sidebarContentRef.current.");
          }
        } else {
          console.log(`sidebarContentRef.current is still null on attempt ${attempt}.`);
          if (attempt < maxRetries) {
            setTimeout(checkSidebarContent, 100);
          } else {
            console.error("Max retries reached. sidebarContentRef.current is still null.");
          }
        }
      };
      checkSidebarContent();
    } else {
      if (sidebarContentRef.current) {
        const historyContent = sidebarContentRef.current.querySelector('.sidebar-history-content');
        if (historyContent) {
          historyContent.classList.remove('open');
          console.log("Removed 'open' class from historyContent. Current classList:", historyContent.classList);
        }
      }
    }
  }, [isSidebarVisible]);

  // UPDATED: toggleSidebarVisibility now only toggles state.
  const toggleSidebarVisibility = () => {
    console.log("toggleSidebarVisibility called.");
    killAllAnimations();
    setIsSidebarVisible((prev) => {
      const newVisibility = !prev;
      console.log("Toggling sidebar. New visibility:", newVisibility);
      return newVisibility;
    });
  };

  // Render grouped classrooms. Each group is an object with a label and an items array.
  // NOTE: Each history-heading and history-item now starts with an inline style of opacity: 0.
  const renderGroupedClassrooms = (groupedGroups) => {
    return (
      <>
        {groupedGroups.map(({ label, items }) => (
          <div key={label} className="history-group">
            <h4 className="history-heading" style={{ opacity: 0 }}>{label}</h4>
            <ul>
              {items.map((classroom) => (
                <li
                  key={classroom.classroom_id}
                  className="history-item"
                  style={{ opacity: 0 }}
                  onClick={async () => {
                    if (classroom.has_started) {
                      navigate(`/studio/classroom/quiz/${classroom.classroom_id}`);
                    } else {
                      navigate(`/studio/classroom/create/${classroom.classroom_id}`);
                    }
                    await localRefreshSidebar();
                  }}
                >
                  <span
                    id={`classroom-title-${classroom.classroom_id}`}
                    className={classroom.classroom_id === typingTitleClassroomId ? 'typing-title' : ''}
                  >
                    {classroom.classroom_id === typingTitleClassroomId
                      ? ''
                      : classroom.title || classroom.topic || `Classroom ${classroom.classroom_id}`}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="classroom-container" style={{ display: 'flex', height: '100vh' }}>
      <div
        style={{
          width: isSidebarVisible ? '240px' : '0px',
          transition: 'width 0.3s ease',
        }}
      >
        {isSidebarVisible && <Sidebar />}
      </div>
      <div className="classroom-content" style={{ flexGrow: 1 }}>
        <div className={`top-classroom-options ${isSidebarVisible ? 'hidden' : ''}`}>
          {!isSidebarVisible && (
            <div
              className="sidebar-open-icon-container"
              onMouseEnter={() => setShowSidebarTooltip(true)}
              onMouseLeave={() => setShowSidebarTooltip(false)}
              onClick={toggleSidebarVisibility}
            >
              <ChatIcon className="sidebar-icon chat-svg-icon" />
              {showSidebarTooltip && (
                <div className="sidebar-tooltip fade-in" onMouseEnter={() => setShowSidebarTooltip(false)}>
                  Open Sidebar
                </div>
              )}
            </div>
          )}
          <div
            className="chat-icon-container"
            onMouseEnter={() => setShowNewChatTooltip(true)}
            onMouseLeave={() => setShowNewChatTooltip(false)}
            onClick={handleNewQuiz}
          >
            <NewChatIcon className="sidebar-icon settings-svg-icon" />
            {showNewChatTooltip && (
              <div className="chat-tooltip fade-in" onMouseEnter={() => setShowNewChatTooltip(false)}>
                New Quiz
              </div>
            )}
          </div>
        </div>
        <ClassroomWindow>
          <Outlet />
        </ClassroomWindow>
      </div>
    </div>
  );
}

export default Classroom;
