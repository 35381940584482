import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import './FillInTheBlankResult.css';

function FillInTheBlankResult({ questionData, difficulty }) {
  const containerRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      containerRef.current,
      { scale: 0.5, opacity: 0 },
      { scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' }
    );
  }, []);

  // Rely on the backend-provided boolean for correctness.
  const isCorrect = questionData.correct;

  return (
    <div className="fib-result" ref={containerRef}>
      <div className="fib-result__header">
        <h2>Fill in the Blank</h2>
      </div>
      <div className="fib-result__question">
        <p>{questionData.question}</p>
      </div>
      <div
        className={`fib-result__user-answer fib-result__user-answer--${difficulty.toLowerCase()} ${
          isCorrect ? 'fib-result__user-answer--correct' : ''
        }`}
      >
        <p>{questionData.userAnswer || 'No answer'}</p>
      </div>
      {isCorrect ? (
        <div className="fib-result__explanation fib-result__explanation--correct">
          <p>{questionData.explanation}</p>
        </div>
      ) : (
        <>
          <div className="fib-result__best-answer">
            <p>{questionData.answer || 'N/A'}</p>
          </div>
          <div className="fib-result__explanation">
            <p>{questionData.explanation}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default FillInTheBlankResult;
